import React, { useEffect, useState } from "react";

import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi } from "../../helpers/ApiFunctions";
import axios from "axios";

import {
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { ImageUpload } from "../../components/ImageUpload";
import Sidebar from "../../components/Sidebar";
import LabelValueContainer from "../../containers/LabelValue";

import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Select,
  Switch,
  message,
  notification,
  Modal,
} from "antd";
import BreadCrumb from "../../components/Breadcrumb";
import configData from "../../config.json";

export default function CubeclubProducts({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [productInventory, setProductInventory] = useState([]);
  const [selected, setSelected] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeImage, setActiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");

  const columnsInventory = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,

      render: (t) => (t ? <div style={{ textAlign: "center" }}>{t}</div> : "-"),
    },
    {
      title: "SKU Code",
      dataIndex: "SKU_code",
      key: "SKU_code",
      width: 300,
      render: (t) =>
        t ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <span>{t}</span>
            <CopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(t).then(
                  () =>
                    message.success({
                      content: "Copied to clipboard!",
                    }),
                  (err) => {
                    message.error({
                      content: "Could not copy text!",
                      err,
                    });
                  }
                );
              }}
              style={{ fontSize: "20px" }}
            />
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "EAN",
      dataIndex: "ean_code",
      key: "ean_code",
      align: "center",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Warranty Tenure",
      dataIndex: "warranty_tenure",
      key: "warranty_tenure",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      filters: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      render: (t) =>
        t ? (
          <div
            className="profile-step-container"
            style={{ background: "#DAFFDA", textAlign: "center" }}
          >
            <div className="profile-step-pill" style={{ color: "#08A908" }}>
              Active
            </div>
          </div>
        ) : (
          <div
            className="profile-step-container"
            style={{ background: "#FFE2E2", textAlign: "center" }}
          >
            <div className="profile-step-pill" style={{ color: "#FF0000" }}>
              Inactive
            </div>
          </div>
        ),
    },

    {
      title: "QR Code Name",
      dataIndex: "QR_code_name",
      key: "QR_code_name",
      align: "center",
      width: 150,

      render: (t) => (t ? t : "-"),
    },
    {
      title: "QR Code Link",
      dataIndex: "QR_code_link",
      key: "QR_code_link",
      align: "center",

      render: (t) =>
        t ? (
          <a
            style={{
              backgroundColor: "#0B0B0B14",
              padding: "3px 12px 3px 16px",
              borderRadius: "12px",
              color: "#000000",
            }}
            href={t}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            {t}
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Amazon Review Link",
      dataIndex: "amazon_review_link",
      key: "amazon_review_link",
      align: "center",
      width: 100,

      render: (t) =>
        t ? (
          <a
            href={t}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            <LinkOutlined />
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Website Review Link",
      dataIndex: "website_review_link",
      key: "website_review_link",
      align: "center",
      width: 100,

      render: (t) =>
        t ? (
          <a
            href={t}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            <LinkOutlined />
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Cube Coins",
      dataIndex: "dopecoins",
      key: "dopecoins",
      align: "center",
      width: 150,

      render: (t) => t,
    },
  ];

  useEffect(() => {
    getProductInventoryList();
  }, []);

  //This function is just for listing the inventory list
  const getProductInventoryList = async (payload = {}) => {
    setLoading(true);
    setActiveIcon("");
    setSelected({});
    setisEdit(false);
    setIsAdd(false);
    setActiveImage(null);
    setFormData({});

    const res = await getApi({ ...payload }, "addProductDetails", "dashboard/");
    if (res.success) {
      setProductInventory(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  //This function is for editing and adding products
  const getProductInventory = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "addProductDetails", "dashboard/");
    if (res.success) {
      uploadProductImage();
      message.success(res.msg);
      form.resetFields();
      setIsModalVisible(false);
    } else {
      setLoading(false);
    }
  };

  const uploadProductImage = async () => {
    if (activeImage !== null) {
      var formdata = new FormData();
      formData?.new_SKU_code
        ? formdata.append("sku_code", formData?.new_SKU_code)
        : formdata.append("sku_code", formData?.SKU_code);
      formdata.append("category", "products");
      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadProductImage");

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadProductImage`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          setFormData({});
          setActiveIcon("");
          setSelected({});
          setisEdit(false);
          setIsAdd(false);
          setActiveImage(null);

          getProductInventoryList();
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getProductInventoryList();
    }
  };

  const handleDelete = (Sr_no) => {
    Modal.confirm({
      title: "Are you sure you want to delete this club?",
      content: "This action cannot be undone",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          console.log(Sr_no);
          const response =  await axios.post(
            `${configData.apiUrl}/api/v1/dashboard/deleteProductDetails`,
            {
              api_name: "deleteProductDetails",
              key: "JWT",
              secret: "CUBECLUB",
              Sr_no: Sr_no,
            }
          );

          if(response.data.success){
            setIsModalVisible(false);
            getProductInventoryList();
            notification.success({
              message: "Club deleted successfully",
            });
          }

          setIsModalVisible(false);
          // Refresh data or update UI here
        } catch (error) {
          notification.error({
            message: "Failed to delete club",
          });
        }
      },
    });
  };

  const Products = (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Warranty Products" />
      <TableContainer
        rowClickHandler={(row) => {
          setSelected(row);
        }}
        loading={loading}
        columns={write === 0 ? columnsInventory : [...columnsInventory]}
        data={productInventory}
        total={total}
        searchFor="Products"
        filter="productInventory"
        functionsHandler={getProductInventoryList}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        isEdit={isEdit}
        setIsAdd={setIsAdd}
      />
    </CardContainer>
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    setSelected({});
  }, [isModalVisible]);

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);
  const isSaveButtonDisabled =
    formData?.Category === undefined ||
    formData?.Category === "" ||
    formData?.sub_category === undefined ||
    formData?.sub_category === "" ||
    formData?.product_name === undefined ||
    formData?.product_name === "" ||
    formData?.SKU_code === undefined ||
    formData?.SKU_code === "" ||
    formData?.warranty_tenure === undefined ||
    formData?.warranty_tenure === "" ||
    formData?.QR_code_name === undefined ||
    formData?.QR_code_name === "" ||
    formData?.ean_code === undefined ||
    formData?.ean_code === "" ||
    formData?.QR_code_link === undefined ||
    formData?.QR_code_link === "" ||
    formData?.amazon_review_link === undefined ||
    formData?.amazon_review_link === "" ||
    formData?.website_review_link === undefined ||
    formData?.website_review_link === "" ||
    formData?.flipkart_review_link === undefined ||
    formData?.flipkart_review_link === "" ||
    activeImage === null ||
    activeIcon === null ||
    activeIcon === "";

  return (
    <>
      {(selected?.Sr_no || isAdd || isEdit) && (
        <Sidebar
          title="Product Details"
          key={"product_details"}
          onClose={() => {
            setSelected({});
            setisEdit(false);
            setIsAdd(false);
            setActiveImage(null);
            setActiveIcon("");
            setFormData({});
          }}
          visible
          footer={
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              {!isAdd && (
                <Col span={8} flex={8}>
                  <div>
                    <Popover
                      placement="topLeft"
                      title={""}
                      content={
                        <div
                          onClick={() => {
                            setActiveIcon(selected?.img_url);

                            setisEdit(!isEdit);
                            setFormData(() => ({
                              Category: selected.Category,
                              sub_category: selected.sub_category,
                              product_name: selected.product_name,
                              SKU_code: selected.SKU_code,
                              warranty_tenure: selected.warranty_tenure,
                              QR_code_name: selected.QR_code_name,
                              ean_code: selected.ean_code,
                              QR_code_link: selected.QR_code_link,
                              is_active: selected.is_active,
                              amazon_review_link: selected.amazon_review_link,
                              website_review_link: selected.website_review_link,
                              flipkart_review_link:
                                selected.flipkart_review_link,

                              demo: selected.demo,
                              FAQ: selected.FAQ,
                              promo: selected.promo,
                              description: selected.description,
                              dopecoins: selected.dopecoins,
                            }));
                          }}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {isEdit ? <EyeOutlined /> : <EditOutlined />}
                          </span>
                          <p style={{ margin: "0px 0px 0px 5px", padding: 0 }}>
                            {isEdit ? "View" : "Edit"}
                          </p>
                        </div>
                      }
                      trigger="click"
                    >
                      <Button
                        style={{
                          margin: 0,
                          padding: "5px 4px",
                          backgroundColor: "lightgray",
                          color: "gray",
                        }}
                      >
                        •••
                      </Button>
                    </Popover>
                    <h4 style={{ marginLeft: 10, display: "inline-block" }}>
                      Action
                    </h4>
                  </div>
                </Col>
              )}
              {(isEdit || isAdd) && (
                <Col span={10} flex={8}>
                  <div
                    style={{
                      display: "flex", // Use flexbox layout
                      justifyContent: "flex-start", // Align buttons to the left
                      alignItems: "center", // Vertically center the buttons
                      marginLeft: !isAdd && "0%",
                    }}
                  >
                    <Button
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        setSelected({});
                        setFormData({});
                        setisEdit(false);
                        setIsAdd(false);
                        setActiveImage(null);
                        setActiveIcon("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading}
                      disabled={
                        isAdd
                          ? isSaveButtonDisabled
                          : formData?.Category === "" ||
                            formData?.sub_category === "" ||
                            formData?.product_name === "" ||
                            formData?.SKU_code === "" ||
                            formData?.warranty_tenure === "" ||
                            formData?.QR_code_name === "" ||
                            formData?.ean_code === "" ||
                            formData?.QR_code_link === "" ||
                            formData?.amazon_review_link === "" ||
                            formData?.website_review_link === "" ||
                            formData?.flipkart_review_link === "" ||
                            activeIcon === "" ||
                            activeIcon === null
                      }
                      type="primary"
                      onClick={() => {
                        isAdd
                          ? getProductInventory({
                              ...formData,

                              is_add: 1,
                            })
                          : formData.SKU_code !== selected?.SKU_code
                          ? getProductInventory({
                              ...formData,

                              is_edit: 1,
                            })
                          : getProductInventory({
                              ...formData,

                              is_edit: 1,
                            });
                      }}
                    >
                      Save
                    </Button>
                    {isEdit && <Button
                      danger
                      type="primary"
                      style={{ marginLeft: 8 }}
                      onClick={() => handleDelete(selected.Sr_no)}
                    >
                      Delete
                    </Button>}
                  </div>
                </Col>
              )}
            </Row>
          }
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row gutter={[10, 20]}>
                    <Col span={24}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Product Image{" "}
                        <small style={{ color: "red" }}>
                          (*Image size should be less than 5MB)
                        </small>
                      </Text>

                      {!(isEdit || isAdd) ? (
                        selected.img_url ? (
                          <Image
                            onClick={(e) => e.stopPropagation()}
                            src={selected?.img_url}
                            style={{
                              objectFit: "cover",
                              borderRadius: "3px",
                              height: "150px",
                              width: "150px",
                            }}
                            preview={selected.img_url ? true : false}
                          />
                        ) : (
                          "-"
                        )
                      ) : (
                        <ImageUpload
                          imageFile={activeImage}
                          setImageFile={setActiveImage}
                          selected={selected}
                          setSelected={setSelected}
                          selectedIcon={activeIcon}
                          setSelectedIcon={setActiveIcon}
                        />
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.product_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              product_name: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the product name"
                        />
                      ) : (
                        <Text>{selected.product_name || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Description
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.description}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the product description"
                        />
                      ) : (
                        <Text>{selected.description || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Category
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.Category}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              Category: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the category"
                        />
                      ) : (
                        <Text>{selected.Category || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Sub-Category
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.sub_category}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              sub_category: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Sub-Category"
                        />
                      ) : (
                        <Text>{selected.sub_category || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        SKU Code
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.SKU_code}
                          onChange={(e) =>
                            isAdd
                              ? setFormData({
                                  ...formData,
                                  SKU_code: e.target.value,
                                })
                              : selected.SKU_code !== formData.SKU_code
                              ? setFormData({
                                  ...formData,
                                  SKU_code: e.target.value,
                                })
                              : setFormData({
                                  ...formData,
                                  SKU_code: selected.SKU_code,
                                  new_SKU_code: e.target.value,
                                })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the SKU Code"
                        />
                      ) : (
                        <Text>{selected.SKU_code || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Warranty Tenure
                      </Text>
                      <Text>
                        {(isEdit || isAdd ? (
                          <Select
                            options={[
                              {
                                value: 1.5,
                                label: "1.5 years",
                              },
                              {
                                value: 1,
                                label: "1 year",
                              },
                              {
                                value: 6,
                                label: "6 months",
                              },
                            ]}
                            style={{ fontSize: 14, width: "100%" }}
                            defaultValue={selected.warranty_tenure}
                            onChange={(e, val) => {
                              setFormData({
                                ...formData,
                                warranty_tenure: val.label,
                              });
                            }}
                            bordered={true}
                            size="large"
                            placeholder="Select warranty tenure"
                          />
                        ) : (
                          <Text>{selected.warranty_tenure || "-"}</Text>
                        )) || "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        QR Code Name
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.QR_code_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              QR_code_name: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the QR Code name"
                        />
                      ) : (
                        <Text>{selected.QR_code_name || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        EAN Code
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.ean_code}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              ean_code: e.target.value.toString(),
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the EAN Code"
                        />
                      ) : (
                        <Text>{selected.ean_code || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        QR Code Link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.QR_code_link}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              QR_code_link: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the QR Code Link"
                        />
                      ) : (
                        <Text>{selected.QR_code_link || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Amazon Review Link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.amazon_review_link}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              amazon_review_link: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Amazon Review Link"
                        />
                      ) : (
                        <Text>{selected.amazon_review_link || "-"}</Text>
                      )}
                    </Col>

                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Website Review Link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.website_review_link}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              website_review_link: isAdd
                                ? e.target.value +
                                  "#shopify-block-loox-product-reviews"
                                : e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Website Review Link"
                        />
                      ) : (
                        <Text>{selected.website_review_link || "-"}</Text>
                      )}
                    </Col>

                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Flipkart Review Link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.flipkart_review_link}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              flipkart_review_link: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Website Review Link"
                        />
                      ) : (
                        <Text>{selected.flipkart_review_link || "-"}</Text>
                      )}
                    </Col>

                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Product Demo Link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.demo}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              demo: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Product Demo Link"
                        />
                      ) : (
                        <Text>{selected.demo || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Product FAQ link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.FAQ}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              FAQ: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Product FAQ link"
                        />
                      ) : (
                        <Text>{selected.FAQ || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Product Promo link
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.promo}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              promo: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Product Promo link"
                        />
                      ) : (
                        <Text>{selected.promo || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Cube Coins
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.dopecoins}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dopecoins: e.target.value.toString(),
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the Cube Coins"
                        />
                      ) : (
                        <Text>{selected.dopecoins}</Text>
                      )}
                    </Col>

                    {!isAdd && (
                      <Col span={12}>
                        <Text type="label" style={{ fontSize: 11 }}>
                          Product Status
                        </Text>
                        {isEdit ? (
                          <Switch
                            defaultChecked={selected.is_active}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                is_active: e ? 1 : 0,
                              })
                            }
                          />
                        ) : (
                          <Text
                            style={{
                              color: selected.is_active ? "green" : "orange",
                            }}
                          >
                            {selected.is_active ? "Active" : "Inactive"}
                          </Text>
                        )}
                      </Col>
                    )}
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
            ]}
          />
        </Sidebar>
      )}

      {Products}
    </>
  );
}
