import React from "react";
import { Footer } from "antd/lib/layout/layout";
import { Typography } from "antd";
import { CopyrightOutlined, TrademarkOutlined } from "@ant-design/icons";

import "./index.css";
import configData from "../../config.json";

export default function Footers() {
  return (
    <Footer className="ftr">
      <CopyrightOutlined style={{ marginTop: 2 }} />
      <Typography.Text style={{ marginLeft: 6, marginRight: 6 }}>
        : {configData.logoText} 2024
      </Typography.Text>
      <TrademarkOutlined />
    </Footer>
  );
}
