import { Avatar, Button, Image, Menu, Popover } from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Link } from "react-router-dom";
import configData from "../../config.json";
import { StoreContext } from "../../helpers/StoreContext";
import { routes } from "../../routes/AllRoutes";
import "./index.css";

import LOGOTEXT from "../../assets/Logo_new.svg";
import LOGO from "../../assets/collapsed.svg";
import { ReactComponent as LIGHTMODE } from "../../assets/light.svg";

import {
  BulbOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";

function getItem(child, label, key, icon, children, type) {
  return {
    child,
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = routes.map(({ child, title, key, icon, children }) =>
  getItem(child, title, key, icon, children)
);

const App = ({ onSideNavCollapse = () => {} }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState("/");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { switcher, themes } = useThemeSwitcher();

  const { SubMenu } = Menu;

  const { storeId: companyId, user, setTitle } = React.useContext(StoreContext);

  useEffect(() => {
    const hrefArr = window.location.href.split("/");
    const href = hrefArr[3] || "/";
    setActiveMenu(href);
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    setIsDarkMode(theme === "dark" ? true : false);
  }, []);

  const logOutHandler = () => {
    setLoading(true);
    localStorage.removeItem("theme");
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("company_id");
    setTimeout(() => window.location.reload(), 500);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    switcher({ theme: isDarkMode ? themes.light : themes.dark });
    localStorage.setItem("theme", isDarkMode ? "light" : "dark");
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: collapsed ? "43px" : "258px",
          top: "15px",
          borderRadius: 4,
          cursor: "pointer",
          background: isDarkMode ? "#222121" : "white",
          boxShadow:
            "0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 11,
          width: 20,
          height: 20,
          textAlign: "center",
        }}
        onClick={() => {
          onSideNavCollapse(!collapsed);
          setCollapsed(!collapsed);
        }}
      >
        {collapsed ? (
          <RightOutlined
            width={5}
            height={5}
            style={{ textAlign: "center", marginLeft: "auto" }}
          />
        ) : (
          <LeftOutlined
            width={5}
            height={5}
            style={{ textAlign: "center", marginLeft: "auto" }}
          />
        )}
      </div>
      <Sider
        className={collapsed ? "sdb-collapsed" : "sdb"}
        collapsible
        trigger={null}
        breakpoint="lg"
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          onSideNavCollapse(broken);
          setCollapsed(broken);
        }}
        onCollapse={(collapsed) => {
          onSideNavCollapse(collapsed);
          setCollapsed(collapsed);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            paddingBottom: "2%",
          }}
        >
          <Link
            onClick={() => {
              setActiveMenu("/");
              setTitle("/");
            }}
            to="/"
          >
            <div style={{ padding: 10 }} className="lgo">
              <Image
                // height={28}
                style={{
                  objectFit: "contain",
                  width: "75%",
                  padding: "2%",
                }}
                src={configData.logo || collapsed ? LOGO : LOGOTEXT}
                preview={false}
                alt={configData.logoText}
              />
            </div>
          </Link>
          <Menu
            className="mnu"
            selectedKeys={[activeMenu]}
            mode="inline"
            inlineCollapsed={collapsed}
            onClick={(e) => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              setActiveMenu(e.key);
              setTitle(e.key);
            }}
          >
            {items
              .filter(({ child }) => child !== 1)
              .map((route, i) =>
                route.key === "users" && companyId ? null : route.children ? (
                  <SubMenu
                    selectedKeys={[activeMenu]}
                    icon={route.icon}
                    key={route.key}
                    title={route.label}
                  >
                    {!collapsed && (
                      <div
                        style={{
                          borderLeft: "1px solid lightgray",
                          height: `${
                            route.children.length > 5
                              ? route.children.length * 44
                              : route.children.length > 3
                              ? route.children.length * 39.5
                              : route.children.length >= 2
                              ? route.children.length * 27.5
                              : route.children.length * 18
                          }px`,
                          marginLeft: 30,
                          position: "absolute",
                          zIndex: 1,
                          borderRadius: 6,
                        }}
                      ></div>
                    )}

                    {route.children.map((routeChild) => (
                      <Menu.Item
                        key={routeChild.label}
                        title={routeChild.label}
                        icon={routeChild.icon}
                      >
                        <Link
                          onClick={() => {
                            setTitle(i === 0 ? "/" : route.label);
                          }}
                          to={routeChild.key}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {!collapsed && (
                              <div
                                style={{
                                  borderLeft: "1px solid lightgray",
                                  borderBottom: "1px solid lightgray",
                                  borderBottomLeftRadius: "6px",
                                  marginBottom: "25px",
                                  height: 25,
                                  width: 20,
                                  marginRight: 10,
                                }}
                              ></div>
                            )}
                            <span>{routeChild.label}</span>
                          </div>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={i === 0 ? "/" : route.key}
                    title={route.label}
                    icon={route.icon}
                    selectedKeys={[activeMenu]}
                  >
                    <Link
                      onClick={() => {
                        setActiveMenu(i === 0 ? "/" : route.label);
                        setTitle(i === 0 ? "/" : route.label);
                      }}
                      to={route.key}
                    >
                      <span>{route.label}</span>
                    </Link>
                  </Menu.Item>
                )
              )}
            {configData.darkMode && (
              <Menu.Item
                style={{ marginBottom: 4 }}
                onClick={toggleTheme}
                key="setting"
                title={isDarkMode ? "Light Mode" : "Dark Mode"}
                icon={
                  isDarkMode ? (
                    <LIGHTMODE
                      style={{ width: 20, height: 20, marginLeft: -4 }}
                    />
                  ) : (
                    <BulbOutlined />
                  )
                }
              >
                <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
              </Menu.Item>
            )}
          </Menu>
          <div
            style={{
              backgroundColor: isDarkMode ? "#3c3b3b" : "#f9f9f9",
              padding: collapsed ? "20% 2%" : "7% 2%",
              margin: collapsed ? "15% 5% 45% 5%" : "15% 5% 15% 5%",
              borderRadius: 6,
            }}
          >
            <div
              className="dropdwn-mnu"
              style={{
                cursor: "default",
                width: "100%",
                color: isDarkMode && "white",
              }}
            >
              <div
                style={{
                  marginRight: collapsed ? "auto" : 8,
                  marginLeft: collapsed
                    ? "auto"
                    : user.first_name?.length < 9
                    ? 20
                    : 5,
                  borderRadius: 100,
                  border: "2px solid #0B0B0B",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "#FFDF00",
                  }}
                  icon={<UserOutlined style={{ color: "#0B0B0B" }} />}
                  shape="circle"
                  src={user.user_profile_image}
                />
              </div>
              {!collapsed && (user.first_name || user.email)}
            </div>

            <Popover
              visible={visible}
              placement={collapsed ? "rightTop" : "top"}
              style={{ borderRadius: 6 }}
              content={
                <>
                  <p style={{ textAlign: "center" }}> Are you sure?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="medium"
                      onClick={() => setVisible(false)}
                      style={{ marginRight: "10%", borderRadius: 6 }}
                    >
                      No
                    </Button>
                    <Button
                      size="medium"
                      type="primary"
                      onClick={logOutHandler}
                      loading={loading}
                      style={{ borderRadius: 6 }}
                    >
                      Logout
                    </Button>
                  </div>
                </>
              }
              trigger="click"
            >
              <div
                className="dropdwn-mnu"
                id="logout"
                style={{
                  margin: "auto",
                  color: isDarkMode && "white",
                  marginTop: 8,
                  justifyContent: "flex-start",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
                key="logout"
                title={"Logout"}
                icon={<LogoutOutlined />}
              >
                <Avatar
                  style={{
                    margin: collapsed
                      ? "0px auto 0px auto"
                      : "0px 8px 0px 20px",

                    backgroundColor: "transparent",
                  }}
                  icon={
                    <LogoutOutlined
                      className="logout-icon"
                      style={{
                        color: isDarkMode ? "white" : "black",
                      }}
                    />
                  }
                  shape="square"
                />

                {!collapsed && "Logout"}
              </div>
            </Popover>
          </div>
        </div>
      </Sider>
    </>
  );
};

export default App;
