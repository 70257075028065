import React from 'react';
import { Modal, Button } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';

const GeneratedLinkModal = ({ isVisible, link, onClose, onCopy }) => {
  return (
    <Modal
      title="Generated Link"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="copy" type="primary" onClick={onCopy}>
          Copy Link
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      getContainer={() => document.body} // Render the modal in the body of the document
      style={{ zIndex: 10000 }} // Ensure the modal has a higher z-index
    >
      <div style={{ textAlign: 'center' }}>
        <p>{link}</p>
        <QRCodeCanvas value={link} size={256} />
      </div>
    </Modal>
  );
};

export default GeneratedLinkModal;