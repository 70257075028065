import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  notification,
  Modal,
} from "antd";
import axios from "axios";
import configData from "../../config.json";
import { QRCodeCanvas } from "qrcode.react";
import GeneratedLinkModal from "./GeneratedLinkModal";
import { generateShareLink } from "../../utils/utils";
import { fetchHostList, joinClub } from "../../utils/utils";

const { Option } = Select;

const ClubCreationForm = ({ onCreateClub }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [hostList, setHostList] = useState([]);
  const [selectedHost, setSelectedHost] = useState(null);

  const apiBaseUrl = `${configData.apiUrl}/api/v1`;

  useEffect(() => {
    fetchHostList(setHostList);
  }, []);

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleSubmit = async (values) => {
    try {
      const userDataString = localStorage.getItem("user_data");
      const userData = userDataString ? JSON.parse(userDataString) : null;

      const formData = new FormData();
      formData.append("club_image", fileList[0]?.originFileObj || "");
      formData.append("club_name", values.club_name);
      formData.append("description", values.description);
      formData.append("club_type", "Guided");
      formData.append("admin_id", userData?.user_id); // Replace with actual admin ID
      formData.append("reward_rule", values.reward_rule);
      formData.append("categories", JSON.stringify(values.categories));
      formData.append("end_date", values.end_date.format("YYYY-MM-DD"));
      formData.append("host_id", values.host_id); // Add host_id to the payload

      const response = await axios.post(
        `${configData.apiUrl}/api/v1/club/createClub`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        const clubId = response.data.data.club_id;

        await joinClub(clubId, values.host_id);


        // Generate token after successful club creation
        await generateShareLink(clubId, setGeneratedLink, setIsModalVisible);

        form.resetFields();
        setFileList([]);
        onCreateClub();
      } else {
        notification.error({
          message: "Error",
          description: response.data.msg || "Failed to create Challenge",
        });
      }
    } catch (error) {
      console.error("Error creating Challenge:", error);
      notification.error({
        message: "Error",
        description: "Failed to create Challenge. Please try again.",
      });
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
    notification.success({
      message: "Link Copied",
      description: "The link has been copied to your clipboard.",
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Challenge Image"
          name="club_image"
          rules={[
            { required: true, message: "Please upload a Challenge image" },
          ]}
        >
          <Upload
            onChange={handleUploadChange}
            fileList={fileList}
            beforeUpload={() => false} // Prevent automatic upload
          >
            <Button>Upload Image</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Challenge Name"
          name="club_name"
          rules={[{ required: true, message: "Please enter a club name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Reward Rules"
          name="reward_rule"
          rules={[{ required: true, message: "Please enter reward rules" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Categories"
          name="categories"
          rules={[
            { required: true, message: "Please select at least one category" },
          ]}
        >
          <Select mode="multiple" style={{ width: "100%" }}>
            <Option value="Swimming">Swimming</Option>
            <Option value="Cycling/spinning">Cycling/spinning</Option>
            <Option value="Yoga">Yoga</Option>
            <Option value="Walking/running">Walking/running</Option>
            <Option value="Strength_training">Strength training</Option>
            <Option value="Sports">Sports</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Host"
          name="host_id"
          rules={[{ required: true, message: "Please select a host" }]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select host"
            optionFilterProp="children"
            onChange={(value) => setSelectedHost(value)}
            filterOption={(input, option) =>
              option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {hostList.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.first_name || "Unnamed"}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="End Date"
          name="end_date"
          rules={[{ required: true, message: "Please select an end date" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Club
          </Button>
        </Form.Item>
      </Form>
      <GeneratedLinkModal
        isVisible={isModalVisible}
        link={generatedLink}
        onClose={handleModalClose}
        onCopy={handleCopyLink}
      />
    </>
  );
};

export default ClubCreationForm;
