import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Image, Row } from "antd";
import React, { useEffect, useState } from "react";

import customerIcon from "../../assets/type-icon-customer.svg";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

const bookingDetailColoumns = [
  {
    title: "Service Type",
    dataIndex: "category_name",
    key: "category_name",
    align: "center",
  },
  {
    title: "Session Type",
    dataIndex: "session_type",
    key: "session_type",
    align: "center",
  },
  {
    title: "Expert",
    dataIndex: "expert_name",
    key: "expert_name",
    align: "center",
  },
  {
    title: "Amount",
    dataIndex: "rate",
    key: "rate",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
  },

  {
    title: "Booked On",
    dataIndex: "selected_session",
    key: "selected_session",
    align: "center",
    render: (text, object) =>
      object?.selected_session
        ? DateFormat(object?.selected_session[0]?.start_date)
        : "-",
  },
];

export default function Customer({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);
  const [bookingCount, setBookingCount] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState({});
  const [waitListType, setWaitListType] = useState(null);

  const columns = [
    {
      title: "Image",
      dataIndex: "user_profile_image",
      key: "user_profile_image",
      align: "center",
      render: (text) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<UserOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, object) => (
        <div style={{ width: "max-content" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (t) => (t ? t : "-"),
    },

    {
      title: "Account type",
      dataIndex: "role_id",
      key: "role_id",
      align: "center",
      render: (t) => t === 3 && <img alt="customer-icon" src={customerIcon} />,
    },
    {
      title: "Waitlist Status",
      dataIndex: "is_waitlisted",
      key: "is_waitlisted",
      align: "center",

      render: (t) => (
        <div
          className="status-container"
          style={{ background: t === 0 ? "#DAFFDA" : "#FFE2E2" }}
        >
          <div
            className="status-holder"
            style={{ color: t === 0 ? "#08A908" : "#FF0000" }}
          >
            {t === 1 ? "Waitlisted" : "Approved"}
          </div>
        </div>
      ),
    },
    {
      title: "Interests",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (t) =>
        t?.length > 0 ? (
          <div className="expertise-container">
            {t.slice(0, 3).map((ele, i) => (
              <div key={i} className="expertise-pill">
                <div className="expertise-data">{ele.name}</div>
              </div>
            ))}
          </div>
        ) : (
          "-"
        ),
    },

    {
      title: "Joining Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) =>
        text ? (
          <div style={{ width: "max-content" }}>{DateFormat(text)}</div>
        ) : (
          "-"
        ),
      sorter: true,
    },
  ];

  useEffect(() => getUsersList(), []);

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 3 },
      "getUsersList",
      "user/"
    );
    if (res.data) {
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  // const getBookingList = async (user = {}) => {
  //   setSelected({ ...user, loading: true });
  //   let type;
  //   user.is_customer === 0 ? (type = "customer") : (type = "expert");
  //   const res = await getApi(
  //     { user_id: user?.id, type, session_type: user?.session_type },
  //     "getBookings",
  //     "dopamine/"
  //   );

  //   if (res.data?.length > 0) {
  //     setSelected({ ...user, bookings: [...res?.data] });
  //     setBookingCount(res.meta?.total);
  //   } else setSelected(user);
  // };

  const updateStatusHandler = async () => {
    setSelected({ ...selected, loading: true });

    const res = await (status.action === "un-waitlist"
      ? saveApi(
          { user_id: selectedRowKeys, is_waitlisted: 0, is_customer: 1 },
          "Profile Approved From Waitlist",
          "approveWaitlisted",
          "dashboard/"
        )
      : saveApi(
          { user_id: selectedRowKeys, is_waitlisted: 1, is_customer: 1 },
          "Profile added to Waitlist",
          "approveWaitlisted",
          "dashboard/"
        ));

    if (res) {
      setSelected({ ...selected, loading: false });

      waitListType === null
        ? getUsersList()
        : getUsersList({ is_waitlisted: waitListType });
      setSelectedRowKeys([]);
      setSelected({});
      setPopup(false);
      setStatus({});
    }
  };

  return (
    <>
      {selected.id && popup && (
        <Popup
          saveText={selected.is_active ? "Yes, Deactivate" : "Yes, Activate"}
          title="Update User Active Status"
          saveHandler={updateStatusHandler}
          visible={true}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
        >
          <Text bold style={{ fontSize: 18, textTransform: "capitalize" }}>
            Are you sure to {selected.is_active ? "Deactivate" : "Activate"} the
            user {selected.name}?
          </Text>
        </Popup>
      )}

      {selected.id && !popup && (
        <Sidebar
          loading={selected.loading}
          title="User Details"
          key={"user_details"}
          onClose={() => setSelected({})}
          visible
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row>
                    <Col span={8}>
                      <Avatar
                        icon={<UserOutlined />}
                        size={100}
                        alt=""
                        src={selected.user_profile_image}
                      />
                    </Col>
                    <Col span={16}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      <Text>{selected.first_name}</Text>
                      <br style={{ marginBottom: "1rem" }} />
                      <Text type="label" style={{ fontSize: 11 }}>
                        Joining Date
                      </Text>
                      <Text>{DateFormat(selected.created_at)}</Text>
                    </Col>
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
              {
                label: "Full Name",
                value: selected.last_name
                  ? selected.first_name + " " + selected.last_name
                  : selected.first_name,
              },
              {
                label: "Mobile Number",
                value: selected.mobile ? selected.mobile : "-",
              },
              { label: "Email", value: selected.email ? selected.email : "-" },
              { label: "Age", value: selected.age ? selected.age : "-" },
              {
                label: "Gender",
                value: selected.gender ? selected.gender : "-",
              },
              {
                label: "Date of Birth",
                value: selected.dob ? selected.dob : "-",
              },
              {
                label: "Height (In cm)",
                value: selected.height ? selected.height : "-",
              },
              {
                label: "Weight (In kg)",
                value: selected.weight ? selected.weight : "-",
              },
              {
                label: "Booking Details",
                value: selected.bookings ? (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => setBookingDetails(selected?.bookings)}
                  >
                    View Booking Details
                  </Button>
                ) : (
                  <Text>No Bookings done yet.</Text>
                ),
                custom: true,
                fullWidth: true,
              },
              {
                label: "Interests",
                value: selected?.category.length
                  ? selected?.category.map((ele, i) => (
                      <>
                        <div
                          style={{ marginBottom: "4px" }}
                          key={i}
                          className="expertise-pill"
                        >
                          <div className="expertise-data">{ele.name}</div>
                        </div>
                      </>
                    ))
                  : "-",
              },
            ]}
          />
        </Sidebar>
      )}

      {bookingDetails?.length && (
        <Popup
          title="Bookings"
          cancelHandler={() => setBookingDetails({})}
          saveHandler={() => setBookingDetails({})}
          saveText="Okay"
          cancelText="Close"
          footer={false}
          loading={bookingDetails.loading}
          visible
        >
          <TableContainer
            loading={loading}
            columns={
              write === 0 ? bookingDetailColoumns : [...bookingDetailColoumns]
            }
            data={bookingDetails}
            total={bookingCount}
            searchFor=""
            dateRangePicker={false}
            exportButton={false}
            // functionsHandler={getBookingList}
          />
        </Popup>
      )}

      {(status.action === "un-waitlist" || status.action === "waitlist") && (
        <Popup
          saveText={selected.is_waitlisted ? "Yes, Approve" : "Yes, Proceed"}
          title="Update User Waitlist Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
            setStatus({});
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to{" "}
            {status.action === "un-waitlist" ? "approve " : "add "}
            {selectedRowKeys.length === 1 ? "this profile " : "these profiles "}
            {status.action === "un-waitlist" ? "from " : "to "} waitlist?
          </Text>
        </Popup>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="User" />
        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          // rowClickHandler={(row) => {
          //   getBookingList(row);
          // }}
          waitListType={waitListType}
          setWaitListType={setWaitListType}
          setStatus={setStatus}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          filter="customers"
          multiple={true}
          data={userList}
          total={total}
          dateRangePicker="true"
          searchFor="Name, Mobile No."
          functionsHandler={getUsersList}
        />
      </CardContainer>
    </>
  );
}
