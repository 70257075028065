import React, { useEffect, useState } from "react";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationOutlined,
  EyeOutlined,
  FileImageTwoTone,
  FilePdfOutlined,
  LinkOutlined,
  PlusSquareOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Col, Form, Image, Input, Row, Select } from "antd";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

const Invoice = ({ write = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSideBar, setLoadingSideBar] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [productList, setProductInventoryList] = useState([]);
  const [total, setTotal] = useState(8);
  const [selected, setSelected] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [status, setStatus] = useState({});
  const [url, setUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const [popup, setPopup] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [isReason, setIsReason] = useState(false);
  const [sort, setSort] = useState(null);

  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      align: "left",
      render: (text, object) => (
        <Text
          style={{ color: "#ff4a01" }}
          onClick={(e) => getUserDetails({ user_id: text })}
        >
          <LinkOutlined /> {text}
        </Text>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Text>Username</Text>
          <div
            style={{
              padding: "1% 4%",
              borderRadius: "5px",
            }}
          >
            <span
              className="sort-container"
              onClick={() => {
                getProductList({ sort });
                setSort((prev) =>
                  prev === "asc" || prev === null ? "desc" : "asc"
                );
              }}
            >
              Sort {sort?.toUpperCase() || "ASC"}
            </span>
            <span
              style={{ marginLeft: "10px", cursor: "pointer", fontSize: 12 }}
              onClick={() => {
                getProductList();
                setSort(null);
              }}
            >
              x
            </span>
          </div>
        </div>
      ),
      dataIndex: "username",
      key: "username",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      align: "left",

      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      align: "center",
      width: 150,
      render: (text, object) => (
        <Text
          onClick={(e) => setPopup(true)}
          style={{ cursor: "pointer", color: "#ff4a01" }}
        >
          {text || "Add Channel"}
          {text ? (
            <EditOutlined style={{ marginLeft: 6 }} />
          ) : (
            <PlusSquareOutlined style={{ marginLeft: 6 }} />
          )}
        </Text>
      ),

      // Not Claimed
      // Processing
      // In Warranty
      // Expired
      // Upload Invoice
    },
    {
      title: "Store Name",
      dataIndex: "store_name",
      key: "store_name",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      width: 150,
      render: (text, object) => <Text>{DateFormat(text) || "-"}</Text>,

      // Not Claimed
    },
    {
      title: "Product Image",
      dataIndex: "img_url",
      key: "img_url",
      align: "center",
      width: 150,
      render: (text, object) =>
        text ? (
          <Image
            onClick={(e) => e.stopPropagation()}
            src={text}
            style={{
              borderRadius: "3px",
              width: 70,
              height: 70,
              objectFit: "contain",
            }}
            preview={true}
          />
        ) : (
          "-"
        ),
    },

    {
      title: "Status",
      dataIndex: "status_details",
      key: "status_details",
      align: "center",
      width: 150,
      render: (t) =>
        (
          <div
            onClick={(e) => e.stopPropagation()}
            className="profile-step-container"
            style={{
              height: "100%",
              background:
                t === "In Warranty"
                  ? "#DAFFDA"
                  : t === "Processing"
                  ? "#D6E4F0"
                  : t === "Not Claimed"
                  ? "#F7E6D1"
                  : "#FFE2E2",

              textAlign: "center",
            }}
          >
            <div
              className="profile-step-pill"
              style={{
                padding: "4%",
                color:
                  t === "In Warranty"
                    ? "#08A908"
                    : t === "Processing"
                    ? "#1F4E7D"
                    : t === "Not Claimed"
                    ? "#E86F18"
                    : "#FF0000",
              }}
            >
              {t || "-"}{" "}
              {t === "Processing" ? (
                <SyncOutlined style={{ marginLeft: 2 }} />
              ) : t === "Not Claimed" ? (
                <ExclamationOutlined />
              ) : t === "In Warranty" ? (
                <CheckOutlined />
              ) : t === "Upload Invoice" ? (
                <FilePdfOutlined />
              ) : (
                <CloseOutlined />
              )}
            </div>
          </div>
        ) || "-",
      filters: [
        {
          text: "Not Claimed",
          value: "Not Claimed",
        },
        {
          text: "Processing",
          value: "Processing",
        },
        {
          text: "In Warranty",
          value: "In Warranty",
        },
        {
          text: "Expired",
          value: "Expired",
        },
        {
          text: "Upload Invoice",
          value: "Upload Invoice",
        },
      ],
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
      align: "center",
      render: (text, object) =>
        text?.slice(-3) === "pdf" ? (
          <h3
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              setUrl(object?.invoice);
            }}
          >
            <FilePdfOutlined style={{ color: "red", fontSize: "30px" }} />
          </h3>
        ) : (
          (text ? (
            <>
              <FileImageTwoTone
                style={{ fontSize: "30px" }}
                onClick={(e) => {
                  setPreview(true);
                }}
              />
            </>
          ) : (
            "-"
          )) || "-"
        ),
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "is_active",
      key: "is_active",
      render: (t, object) =>
        t === 1 ? (
          <Button
            onClick={() => setIsDelete(true)}
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
          ></Button>
        ) : (
          <Text
            style={{
              cursor: "pointer",
              color: "red",
              fontSize: 12,
              textAlign: "center",
            }}
            onClick={(e) => setIsReason(true)}
          >
            <EyeOutlined /> Deletion Reason
          </Text>
        ),
    },
  ];

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    popup === true && getChannelList();
  }, [popup]);

  const getUserDetails = async (payload = {}) => {
    setLoadingSideBar(true);
    setSideBar(true);
    const res = await getApi({ ...payload }, "getUserDetails", "user/");
    if (res.data) {
      setUserDetails(res.data);
    }
    setLoadingSideBar(false);
  };

  const getProductList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, is_fetch: 1 },
      "userProdInvoice",
      "dashboard/"
    );
    if (res.data) {
      setProductInventoryList(res.data);
      setTotal(res?.meta?.total);
    }
    setLoading(false);
  };

  const getChannelList = async (payload = {}) => {
    const res = await getApi(
      { ...payload, get_channels: 1 },
      "userProdInvoice",
      "dashboard/"
    );
    if (res.data) {
      setChannelData(res.data[0]?.product_details);
    }
  };

  const updateProductList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "userProdInvoice", "dashboard/");
    if (res.success) {
      setProductInventoryList(res.data);
      setTotal(res.meta);
      getProductList();
      setStatus({});
      setSelectedRowKeys([]);
      setDeleteReason("");
      setIsDelete(false);
    }
    setLoading(false);
  };
  const updateStatusHandler = async (payload = {}) => {
    updateProductList({
      is_edit: 1,
      status_details: status.action,
      approved_id: selectedRowKeys,
    });
  };

  const deleteHandler = async (payload = {}) => {
    updateProductList({
      reason: deleteReason,
      invoice_id: selected?.id,
      is_delete: 1,
    });
  };
  const updateInvoiceData = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "userProdInvoice", "dashboard/");
    if (res.success) {
      setPopup(false);

      getProductList();
    }
    setLoading(false);
  };

  const onFinish = (data) => {
    updateInvoiceData({
      invoice_id: selected?.id,
      is_update: 1,
      channel: data.channel,
      note: data.note,
    });
  };
  return (
    <>
      {popup && (
        <Popup
          className="Modal"
          visible={popup}
          footer={false}
          saveText="Save"
          title="Channel Update"
          width="350px"
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading || loading}
          visibleonCancel={() => setPopup(false)}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            noValidate
          >
            <label for="channel" required>
              Channel :
            </label>
            <Form.Item
              name="channel"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select a channel"
                allowClear
                defaultValue={selected?.channel}
              >
                {channelData?.map((channelName) => (
                  <option value={channelName}>{channelName}</option>
                ))}
              </Select>
            </Form.Item>
            <label for="note" required>
              Note :
            </label>
            <Form.Item
              name="note"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                defaultValue={selected?.note}
                placeholder="Enter note"
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Popup>
      )}
      {url && (
        <Popup
          title="Document preview"
          saveText="Okay"
          cancelText="Close"
          footer={false}
          style={{ objectFit: "cover" }}
          cancelHandler={() => setUrl("")}
          saveHandler={() => setUrl("")}
          frameBorder="0"
          visible
        >
          <iframe
            title="document-preview"
            style={{
              width: "100%",
              height: "80vh",
              borderRadius: "2px",
            }}
            src={url}
          ></iframe>
        </Popup>
      )}

      {status?.action && (
        <Popup
          saveText={"Yes"}
          title="Warranty Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setStatus({});
            setSelectedRowKeys([]);
          }}
          loading={loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            {`Are you sure you want to update the status to ${status?.action} ?`}
          </Text>
        </Popup>
      )}

      {isDelete && (
        <Popup
          width="410px"
          saveText={"Delete"}
          title="Delete Warranty"
          saveHandler={deleteHandler}
          isDisabled={deleteReason?.length < 1}
          cancelHandler={() => {
            setIsDelete(false);
            setSelectedRowKeys([]);
            setDeleteReason("");
          }}
          loading={loading}
          visible
        >
          <Text bold style={{ fontSize: 15 }}>
            {`Are you sure you want to delete this warranty ?`}
          </Text>
          <Input.TextArea
            style={{ marginTop: 10 }}
            rows={3}
            onChange={(e) => setDeleteReason(e.target.value)}
            placeholder="Write the reason for deletion"
          ></Input.TextArea>
        </Popup>
      )}

      {isReason && (
        <Popup
          width="410px"
          footer={false}
          title="Deletion Reason"
          cancelHandler={() => {
            setIsReason(false);
            setSelected({});
          }}
          visible
        >
          <Text bold style={{ fontSize: 15 }}>
            {selected?.reason || "-"}
          </Text>
        </Popup>
      )}

      {preview && (
        <div style={{ display: "none" }}>
          (
          <Image
            onPreviewClose={() => setPreview(!preview)}
            src={selected.invoice}
            preview={{
              visible: preview,
            }}
          />
          )
        </div>
      )}

      {sideBar && (
        <Sidebar
          loading={loadingSideBar}
          title="User's Detail"
          key={"user"}
          onClose={() => {
            setSideBar(false);
            setSelected({});
            setUserDetails({});
          }}
          visible
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row>
                    <Col span={8}>
                      <Avatar
                        icon={<UserOutlined />}
                        size={100}
                        alt=""
                        src={userDetails?.user_profile_image}
                      />
                    </Col>
                    <Col span={16}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      <Text>{userDetails?.first_name}</Text>
                    </Col>
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
              { label: "Username", value: userDetails?.username },
              { label: "Mobile Number", value: userDetails?.mobile },
              { label: "Email", value: userDetails?.email },
              { label: "Gender", value: userDetails?.gender || "-" },
              { label: "Date of Birth", value: userDetails?.dob || "-" },
            ]}
          />
        </Sidebar>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="App Warranty Approval" />
        <TableContainer
          parseButton="export"
          rowKeyName="id"
          status={status}
          setStatus={setStatus}
          sort={sort}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          multiple={true}
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
          }}
          data={productList}
          total={total}
          filter="invoice"
          searchFor="SKU code, User Id"
          functionsHandler={getProductList}
          dateRangePicker="true"
        />
      </CardContainer>
    </>
  );
};

export default Invoice;
