import {
  CheckOutlined,
  CloseOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Image } from "antd";
import React, { useEffect, useState } from "react";

import moment from "moment";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi, saveApi } from "../../helpers/ApiFunctions";

export default function PostReports({ write = 0 }) {
  const waitlistedTotal = 0;
  const nonWaitlistedTotal = 0;

  const [loading, setLoading] = useState(false);
  const [totalPostReports, setTotalPostReports] = useState(8);
  const [postReportList, setPostReportList] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  useEffect(() => {
    getPostReports({ type: "post", api_name: "getReportsData" });
  }, [selectedData]);

  const getPostReports = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      {...payload, type:"post" },
      "getReportsData",
      "dashboard/"
    );
    if (res.data) {
      console.log(res.data);
      setPostReportList(res.data);
      setTotalPostReports(res.meta?.total);
    }
    setLoading(false);
  };

  const getPostReportData = async (post_id) => {
    
    const res = await getApi(
      { post_id:post_id },
      "getReportsData",
      "dashboard/"
    );
    if (res.data) {
      console.log("res data", res.data);
      setSelectedData(res.data);
    }
  };

  const handleRowClick = (row) => {
    const post_id = row.reported_post;
    getPostReportData(post_id);
    setIsSidebarOpen(true);
  };

  const resetFunc = () => {
    setSelectedData([]);
    setIsSidebarOpen(false);
    // setisCreate(false);
    // setIsEdit(false);
    // form.resetFields();
    // setActiveImage(null);
    // setActiveIcon("");
  };

  const postReportColumns = [
    {
      title: "Post",
      dataIndex: "proof",
      key: "proof",
      align: "center",

      render: (text, object) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<PictureOutlined />}
              shape="square"
              size={24}
              style={{ height: "24px", width: "24px" }}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Post ID",
      dataIndex: "reported_post",
      key: "reported_post",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Posted By",
      dataIndex: "posted_by",
      key: "posted_by",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Valid Report Count",
      dataIndex: "valid_report_count",
      key: "reports_count",
      align: "center",
      render: (t) => (t == null ? "-" : t),
    },
    {
      title: "Invalid Report Count",
      dataIndex: "invalid_report_count",
      key: "reports_count",
      align: "center",
      render: (t) => (t == null ? "-" : t),
    },
    {
      title: "Status",
      dataIndex: "is_banned",
      key: "is_banned",
      render: (t) => (t === 1 ? "Banned" : "Not banned"),
    },
  ];

  const reportColumns = [
    {
      title: "Reported By",
      dataIndex: "reported_by",
      key: "reported_by",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Date",
      dataIndex: "reported_on",
      key: "reported_on",
      align: "center",

      render: (t) => (t ? moment(t).format("DD/MM/yyyy") : "-"),
    },
    {
      title: "Report validity",
      dataIndex: "is_valid",
      key: "is_valid",
      align: "center",

      render: (t) => (t == 1 ? "Valid" : "Invalid"),
    },
  ];

  const banHandler = async (post_id, is_banned) => {
    setLoading(true);
    
    let msg = "";
    const res = await saveApi(
      { post_id: post_id, is_banned: is_banned },
      msg,
      "manageReport",
      "dashboard/"
    );
   

    if (res) {
      console.log(res);
      getPostReports({ type: "post", api_name: "getReportsData" });
    }
    setSelected({});
    setLoading(false);
    setApprovePopup(false);
    setRejectPopup(false);
    setIsSidebarOpen(false);
  };

  return (
    <>
      {approvePopup && (
        <Popup
          saveText="Confirm"
          title="Confirm Post Ban"
          saveHandler={() => banHandler(selected.reported_post, 1)}
          cancelHandler={() => {
            setApprovePopup(false);
          }}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to ban this post?
          </Text>
        </Popup>
      )}

      {rejectPopup && (
        <Popup
          saveText="Confirm"
          title="Reject Post Ban"
          saveHandler={() => banHandler(selected.reported_post, 0)}
          cancelHandler={() => {
            setRejectPopup(false);
          }}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to reject the request to ban this post?
          </Text>
        </Popup>
      )}

      <Sidebar
        title="Post Reports"
        reports
        onClose={() => {
          resetFunc();
        }}
        visible={isSidebarOpen}
      >
        <div style={{ display: "flex", gap: 50, alignItems: "center" }}>
          <Image
            onClick={(e) => e.stopPropagation()}
            src={selectedData[0]?.proof}
            style={{
              borderRadius: "0.5rem",
              height: "150px",
              width: "150px",
              objectFit: "cover",
            }}
            placeholder={
              <Avatar
                icon={<PictureOutlined />}
                shape="square"
                size={150}
                style={{ height: "150px", width: "150px" }}
              />
            }
            preview={selectedData[0]?.proof ? true : false}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div>
              <Text type="label" style={{ fontSize: 11 }}>
                Post ID
              </Text>
              <Text>{selected.reported_post}</Text>
            </div>
            <div>
              <Text type="label" style={{ fontSize: 11 }}>
                Description
              </Text>
              <Text>{selected.description}</Text>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                onClick={() => setApprovePopup(true)}
                icon={<CheckOutlined />}
                style={{ backgroundColor: "#08a908", color: "#FFFFFF" }}
              >
                Ban post
              </Button>
              <Button
                onClick={() => setRejectPopup(true)}
                icon={<CloseOutlined />}
                style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
              >
                Revalidate post
              </Button>
            </div>
          </div>
        </div>
        {selectedData[0]?.is_banned ? (
          <Text type="title" style={{ color: "#FF0000", marginTop: 20 }}>
            *Post has been banned
          </Text>
        ) : selectedData[0]?.is_flagged ? (
          <Text type="title" style={{ color: "#FF0000", marginTop: 20 }}>
            *Post has been flagged and blurred from the App feed
          </Text>
        ) : (
          <></>
        )}
        <Text type="title" style={{ marginTop: 40 }}>
          Report Logs
        </Text>
        <TableContainer columns={[...reportColumns]} data={selectedData} />
      </Sidebar>

      <CardContainer hoverable={false}>
        <BreadCrumb title="Reported Posts" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? postReportColumns : [...postReportColumns]}
          rowClickHandler={(row) => {
            setSelected(row);
            handleRowClick(row);
          }}
          data={postReportList}
          total={totalPostReports}
          searchFor="Post ID"
          functionsHandler={() =>
            getPostReports({ type: "post", api_name: "getReportsData" })
          }
        />
      </CardContainer>
    </>
  );
}
