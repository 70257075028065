import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Image } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import "./index.css";

export default function UserReports({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [totalUserReports, setTotalUserReports] = useState(8);
  const [userReportList, setUserReportList] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  useEffect(() => {
    getUserReports();
  }, [selectedData]);

  useEffect(() => {
    console.log(isSidebarOpen);
  }, [isSidebarOpen]);

  const getUserReports = async (payload = {}) => {
    
    setLoading(true);
    const res = await getApi(
      {...payload, type:"user" },
      "getReportsData",
      "dashboard/"
    );
    
    if (res.data) {
      console.log(res)
      setUserReportList(res.data);
      setTotalUserReports(res.meta?.total);
    }
    setLoading(false);
  };


  const resetFunc = () => {
    console.log("Reset func called");
    setSelected([]);
    setIsSidebarOpen(false);
  };

  const userReportColumns = [
    {
      title: "Profile Image",
      dataIndex: "profile",
      key: "profile",
      align: "center",

      render: (text, object) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<UserOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
              size={24}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "reported_user",
      key: "reported_user",
      align: "center",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Valid Report Count",
      dataIndex: "valid_report_count",
      key: "reports_count",
      align: "center",
      render: (t) => (t == null ? "-" : t),
    },
    {
      title: "Invalid Report Count",
      dataIndex: "invalid_report_count",
      key: "reports_count",
      align: "center",
      render: (t) => (t == null ? "-" : t),
    },
    {
      title: "Status",
      dataIndex: "is_banned",
      key: "is_banned",
      render: (t) => {console.log("t",t) 
      return(t == 1 ? "Banned" : "Not banned")},
    },
  ];

  const getUserReportData = async (user_id) => {
    const payload = {
      user_id: user_id,
      api_name: "getReportsData",
    };
    const res = await getApi(
      {user_id: user_id },
      "getReportsData",
      "dashboard/"
    );
    if (res.data) {
      console.log(res.data);
      setSelectedData(res.data);
    }
  };

  const handleRowClick = (row) => {
    const user_id = row.reported_user_id;
    getUserReportData(user_id);
    setIsSidebarOpen(true);
  };

  const reportColumns = [
    {
      title: "Reported By",
      dataIndex: "reported_by",
      key: "reported_by",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Report category",
      dataIndex: "report_category",
      key: "report_category",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Pretending to be (ID)",
      dataIndex: "pretending_to_be",
      key: "pretending_to_be",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Reported On",
      dataIndex: "reported_on",
      key: "reported_by",
      align: "center",

      render: (t) => (t ? moment(t).format("DD/MM/yyyy") : "-"),
    },
    {
      title: "Report validity",
      dataIndex: "is_valid",
      key: "is_valid",
      align: "center",

      render: (t) => (t == 1 ? "Valid" : "Invalid"),
    },
  ];

  const banHandler = async (reported_user_id, is_banned) => {
    setLoading(true);
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    let msg = "";
    const res = await saveApi(
      { reported_to: reported_user_id, is_banned: is_banned },
      msg,
      "manageReport",
      "dashboard/"
    );
    
    
    if (res) {
      console.log(res)
      getUserReports();
    }
    setSelected({});
    setLoading(false);
    setApprovePopup(false);
    setRejectPopup(false);
    setIsSidebarOpen(false)
  };

  return (
    <>
      {approvePopup && (
        <Popup
          saveText="Confirm"
          title="Confirm User Ban"
          saveHandler={() => banHandler(selected.reported_user_id, 1)}
          cancelHandler={() => {
            setApprovePopup(false);
          }}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to ban user <b>{selected.reported_user}</b>?
          </Text>
        </Popup>
      )}

      {rejectPopup && (
        <Popup
          saveText="Confirm"
          title="Reject User Ban"
          saveHandler={() => banHandler(selected.reported_user_id, 0)}
          cancelHandler={() => {
            setRejectPopup(false);
          }}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to reject the request to ban the user <b>{selected.reported_user}</b>?
          </Text>
        </Popup>
      )}

      <Sidebar
        title="User Reports"
        reports={true}
        onClose={() => {
          resetFunc();
        }}
        visible={isSidebarOpen}
      >
        <div style={{ display: "flex", gap: 50, alignItems: "center" }}>
          <Image
            onClick={(e) => e.stopPropagation()}
            src={selectedData[0]?.proof}
            style={{
              borderRadius: "0.5rem",
              height: "150px",
              width: "150px",
              objectFit: "cover",
            }}
            placeholder={
              <Avatar
                icon={<UserOutlined />}
                shape="square"
                size={150}
                style={{ height: "150px", width: "150px" }}
              />
            }
            preview={selectedData[0]?.proof ? true : false}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div>
              <Text type="label" style={{ fontSize: 11 }}>
                User ID
              </Text>
              <Text>{selected.reported_user_id}</Text>
            </div>
            <div>
              <Text type="label" style={{ fontSize: 11 }}>
                Username
              </Text>
              <Text>{selected.reported_user}</Text>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                onClick={() => setApprovePopup(true)}
                icon={<CheckOutlined />}
                style={{ backgroundColor: "#08a908", color: "#FFFFFF" }}
              >
                Ban user
              </Button>
              <Button
                onClick={() => setRejectPopup(true)}
                icon={<CloseOutlined />}
                style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
              >
                Revalidate user
              </Button>
            </div>
          </div>
        </div>
        {selectedData[0]?.is_banned ? (
          <Text type="title" style={{ color: "#FF0000", marginTop: 20 }}>
            *User has been banned
          </Text>
        ) : selectedData[0]?.is_flagged ? (
          <Text type="title" style={{ color: "#FF0000", marginTop: 20 }}>
            *User has been flagged and blurred from the App feed
          </Text>
        ) : (
          <></>
        )}

        <Text type="title" style={{ marginTop: 40 }}>
          Report Logs
        </Text>
        <TableContainer columns={[...reportColumns]} data={selectedData} />
      </Sidebar>

      <CardContainer hoverable={false}>
        <BreadCrumb title="Reported Users" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? userReportColumns : [...userReportColumns]}
          rowClickHandler={(row) => {
            setSelected(row);
            handleRowClick(row);
          }}
          data={userReportList}
          total={totalUserReports}
          searchFor="Name"
          functionsHandler={getUserReports}
        />
      </CardContainer>
    </>
  );
}
