import { notification } from "antd";
import configData from "../config.json";
import axios from "axios";

export const generateShareLink = async (
  clubId,
  setGeneratedLink,
  setIsModalVisible,
  setDrawerVisible
) => {
  try {
    const apiBaseUrl = `${configData.apiUrl}/api/v1`;

    const response = await axios.post(
      `${apiBaseUrl}/club/generateChallengeLink`,
      {
        api_name: 'generateChallengeLink',
        key: 'JWT',
        secret: 'CUBECLUB',
        club_id: clubId
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const link = response.data.data.shortLink;
    setGeneratedLink(link);
    setIsModalVisible(true);

    if (setDrawerVisible) {
      setDrawerVisible(false);
    }

    return link;
  } catch (error) {
    console.error('Error generating share link:', error);
    throw error;
  }
};

export const fetchHostList = async (setHostList) => {
  const apiBaseUrl = `${configData.apiUrl}/api/v1`;
  try {
    const response = await axios.post(
      `${apiBaseUrl}/dashboard/getExperts`,
      {
        api_name: "getExperts",
        key: "JWT",
        secret: "CUBECLUB",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.data.success) {
      setHostList(response.data.data);
    } else {
      notification.error({
        message: "Error",
        description: "Failed to fetch user list",
      });
    }
  } catch (error) {
    console.error("Error fetching user list:", error);
    notification.error({
      message: "Error",
      description: "Failed to fetch user list. Please try again.",
    });
  }
};

export const joinClub = async (clubId, userId) => {
  const apiBaseUrl = `${configData.apiUrl}/api/v1`;
  try {
    const response = await axios.post(`${apiBaseUrl}/club/joinClub`, {
      api_name: "joinClub",
      key: "JWT",
      secret: "CUBECLUB",
      club_id: clubId,
      user_id: userId,
    });

    if (response.data.success) {
      return true;
    } else {
      notification.error({
        message: "Error",
        description: response.data.msg || "Failed to join the club",
      });
    }
  } catch (error) {
    console.error("Error joining club:", error);
    notification.error({
      message: "Error",
      description: "Failed to join the club. Please try again.",
    });
  }
};

export const removeMember = async (clubId, userId) => {
  const apiBaseUrl = `${configData.apiUrl}/api/v1`;
  try {
    const response = await axios.post(`${apiBaseUrl}/club/removeMember`, {
      api_name: "removeMember",
      key: "JWT",
      secret: "CUBECLUB",
      club_id: clubId,
      user_id: userId,
    });

    if (response.data.success) {
      return true;
    } else {
      notification.error({
        message: "Error",
        description: response.data.msg || "Failed to remove the member",
      });
    }
  } catch (error) {
    console.error("Error removing member:", error);
    notification.error({
      message: "Error",
      description: "Failed to remove the member. Please try again.",
    });
  }
};
