import React, { useState, useEffect } from "react";
import {
  UserOutlined,
  FormOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Image,
  Table,
  Typography,
  DatePicker,
  Button,
  Select,
  Input,
  Drawer,
  Form,
  Row,
  Col,
  Tag,
  Descriptions,
  Divider,
  notification,
  Upload,
  Modal,
} from "antd";
import axios from "axios";
import moment from "moment";
import ClubCreationForm from "./ClubCreationForm";
import configData from "../../config.json";
import GeneratedLinkModal from "./GeneratedLinkModal";
import { generateShareLink } from "../../utils/utils";
import { fetchHostList, removeMember, joinClub } from "../../utils/utils";

const { Title } = Typography;
const { Option } = Select;

const AppChallenges = () => {
  const [clubs, setClubs] = useState([]);
  const [filteredClubs, setFilteredClubs] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedClub, setSelectedClub] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [createClubDrawerVisible, setCreateClubDrawerVisible] = useState(false); // New state for "Create Club" drawer
  const [generatedLink, setGeneratedLink] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hostList, setHostList] = useState([]);
  const [selectedHost, setSelectedHost] = useState(null);

  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [fileList, setFileList] = useState([]);

  const userDataString = localStorage.getItem("user_data");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const apiBaseUrl = `${configData.apiUrl}/api/v1`;

  console.log(" apiurl", apiBaseUrl);

  useEffect(() => {
    if (selectedClub) {
      form.setFieldsValue({
        club_name: selectedClub.club_name,
        description: selectedClub.description,
        club_type: selectedClub.club_type,
        reward_rule: selectedClub.reward_rule,
        categories:
          typeof selectedClub.categories === "string"
            ? JSON.parse(selectedClub.categories)
            : selectedClub.categories,
        end_date: moment(selectedClub.end_date),
        host_id: selectedClub.host_id,
      });
    }
  }, [selectedClub, form]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      // Get all form values
      const values = await form.validateFields();
      console.log("Form values before submission:", values);

      // Create FormData
      const formData = new FormData();

      // Append file if exists
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("club_image", fileList[0].originFileObj);
      }

      // Append other form values
      formData.append("club_name", values.club_name);
      formData.append("admin_id", userData.user_id);
      formData.append("club_id", selectedClub.club_id);
      formData.append("description", values.description);
      formData.append("club_type", selectedClub.club_type); // Preserve original club type
      formData.append("reward_rule", values.reward_rule);

      // Handle categories
      const categories = Array.isArray(values.categories)
        ? JSON.stringify(values.categories)
        : values.categories;
      formData.append("categories", categories);

      // Format end date
      const endDate = values.end_date
        ? moment(values.end_date).format("YYYY-MM-DD")
        : moment(selectedClub.end_date).format("YYYY-MM-DD");
      formData.append("end_date", endDate);

      formData.append("host_id", values.host_id);

      // Log FormData contents for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const response = await axios.post(
        `${apiBaseUrl}/club/updateClub`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        if (selectedClub.host_id !== values.host_id) {
          // Remove previous host
          await removeMember(selectedClub.club_id, selectedClub.host_id);
          // Add new host
          await joinClub(selectedClub.club_id, values.host_id);
        }
  
        notification.success({
          message: "Success",
          description: "Club details updated successfully!",
        });

        // Reset filters here
        setStartDateFilter(null);
        setEndDateFilter(null);
        setCategoryFilter(null);
        setSearchText("");

        fetchClubData();
        setDrawerVisible(false);
        setIsEditing(false);
      } else {
        notification.error({
          message: "Error",
          description:
            response.data.msg || "Failed to update Challenge details",
        });
      }
    } catch (error) {
      console.error("Error in handleSave:", error);
      notification.error({
        message: "Error",
        description:
          error.response?.data?.msg || "Failed to update club details",
      });
    }
  };
  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  useEffect(() => {
    fetchClubData();
  }, []);

  useEffect(() => {
    setFilteredClubs(clubs);
  }, [clubs]);

  const fetchClubData = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/club/getClubsList`, {
        key: "JWT",
        secret: "CUBECLUB",
        api_name: "getClubsList",
        user_id: userData.user_id,
        club_type: "Guided",
      });

      if (response.data.success) {
        setClubs(response.data.data);
      } else {
        console.error("Failed to fetch Challenge:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching Challenge data:", error);
    }
  };

  const fetchClubDetails = async (clubId) => {
    const userDataString = localStorage.getItem("user_data");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    try {
      const response = await axios.post(`${apiBaseUrl}/club/clubDetails`, {
        key: "JWT",
        secret: "CUBECLUB",
        api_name: "clubDetails",
        club_id: clubId,
        user_id: userData.user_id,
        club_type: "Guided",
      });

      if (response.data.success) {
        setSelectedClub(response.data.data);
        setDrawerVisible(true);
      } else {
        console.error("Failed to fetch Challenge details:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching Challenge details:", error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDateFilter(date);
  };

  const handleEndDateChange = (date) => {
    setEndDateFilter(date);
  };

  const handleCategoryChange = (value) => {
    setCategoryFilter(value);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleRowClick = (record) => {
    fetchClubDetails(record.club_id);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setIsEditing(false);
  };

  const handleDelete = async (clubId) => {
    const userDataString = localStorage.getItem("user_data");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    try {
      const response = await axios.post(`${apiBaseUrl}/club/deleteClub`, {
        key: "JWT",
        secret: "CUBECLUB",
        api_name: "deleteClub",
        club_id: clubId,
        admin_id: userData?.user_id,
      });

      if (response.data.success) {
        notification.success({
          message: "Challenge Deleted",
          description: "The Challenge has been successfully deleted.",
        });
        setDrawerVisible(false); // Close the drawer
        fetchClubData(); // Refresh the table data
      } else {
        notification.error({
          message: "Failed to Delete Challenge",
          description: response.data.msg,
        });
      }
    } catch (error) {
      console.error("Error deleting Challenge:", error);
    }
  };

  const applyFilters = (clubs) => {
    // If no filters are set, return the clubs without any filtering
    if (!startDateFilter && !endDateFilter && !categoryFilter && !searchText) {
      return clubs;
    }

    return clubs.filter((club) => {
      const clubStartDate = moment(club.created_at);
      const clubEndDate = moment(club.end_date);
      const clubName = club.club_name.toLowerCase();
      const clubCategories = club.categories.toLowerCase();

      // Apply date filters
      if (
        (startDateFilter && clubStartDate.isBefore(startDateFilter, "day")) ||
        (endDateFilter && clubEndDate.isAfter(endDateFilter, "day"))
      ) {
        return false;
      }

      // Apply category filter
      if (
        categoryFilter &&
        club.categories &&
        !clubCategories.includes(categoryFilter.toLowerCase())
      ) {
        return false;
      }

      // Apply search filter
      if (
        searchText &&
        !clubName.includes(searchText.toLowerCase()) &&
        !clubCategories.includes(searchText.toLowerCase())
      ) {
        return false;
      }

      return true;
    });
  };

  const showCreateClubDrawer = () => {
    setCreateClubDrawerVisible(true); // Open create club drawer
  };

  const handleCreateClubDrawerClose = () => {
    setCreateClubDrawerVisible(false); // Close create club drawer
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setIsEditing(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink);
    notification.success({
      message: "Link Copied",
      description: "The link has been copied to your clipboard.",
    });
  };

  const clubChallengesdetails = [
    {
      title: "Challenge Image",
      dataIndex: "club_image",
      key: "club_image",
      align: "center",
      render: (text) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<UserOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
              size={24}
            />
          }
          preview={!!text}
        />
      ),
    },
    {
      title: "Challenge Name",
      dataIndex: "club_name",
      key: "club_name",
      align: "center",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Categories",
      dataIndex: "categories",
      key: "categories",
      align: "center",
      render: (t) => (t ? t : "-"),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Select
            defaultValue={categoryFilter}
            onChange={handleCategoryChange}
            style={{ width: 180 }}
            placeholder="Select Category"
          >
            <Option value="Walking/running">Walking/running</Option>
            <Option value="Cycling/spinning">Cycling/spinning</Option>
            <Option value="Swimming">Swimming</Option>
            <Option value="Yoga">Yoga</Option>
            <Option value="Strength_training">Strength_training</Option>
            <Option value="Sports">Sports</Option>
          </Select>
        </div>
      ),
      onFilter: (value, record) => record.categories === value,
    },
    {
      title: "Members Count",
      dataIndex: "members_count",
      key: "members_count",
      align: "center",
      render: (t) => (t != null ? t : "-"),
    },
    {
      title: "Admin Name",
      dataIndex: "admin_name",
      key: "admin_name",
      align: "center",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Host Name",
      dataIndex: "host_name",
      key: "host_name",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Start Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (t) => (t ? new Date(t).toLocaleDateString() : "-"),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={startDateFilter}
            onChange={handleStartDateChange}
            style={{ width: "100%" }}
            placeholder="Select Start Date"
          />
        </div>
      ),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      render: (t) => (t ? new Date(t).toLocaleDateString() : "-"),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <DatePicker
            value={endDateFilter}
            onChange={handleEndDateChange}
            style={{ width: "100%" }}
            placeholder="Select End Date"
          />
        </div>
      ),
    },
    {
      title: "Reward Rules",
      dataIndex: "reward_rule",
      key: "reward_rule",
      align: "center",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Challenge Active Score",
      dataIndex: "club_active_score",
      key: "club_active_score",
      align: "center",
      render: (t) => (t != null ? `${t}%` : "-"),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      filters: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (t) =>
        t ? (
          <div
            className="profile-step-container"
            style={{ background: "#DAFFDA", textAlign: "center" }}
          >
            <div className="profile-step-pill" style={{ color: "#08A908" }}>
              Active
            </div>
          </div>
        ) : (
          <div
            className="profile-step-container"
            style={{ background: "#FFE2E2", textAlign: "center" }}
          >
            <div className="profile-step-pill" style={{ color: "#FF0000" }}>
              Inactive
            </div>
          </div>
        ),
    },
  ];

  return (
    <div style={{ margin: "16px" }}>
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormOutlined style={{ fontSize: "24px", marginRight: "8px" }} />
            <Title level={3} style={{ margin: 0 }}>
              App Challenges
            </Title>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchTextChange}
              style={{ marginRight: "16px" }}
            />
            <Button type="primary" onClick={showCreateClubDrawer}>
              Create Challenges
            </Button>
          </div>
        </div>
      </div>

      <div style={{ overflow: "auto" }}>
        <Table
          dataSource={applyFilters(filteredClubs)}
          columns={[
            ...clubChallengesdetails,
            {
              title: "Share Link",
              key: "share_link",
              align: "center",
              render: (_, record) => (
                <Button
                  icon={<ShareAltOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    generateShareLink(
                      record.club_id,
                      setGeneratedLink,
                      setIsModalVisible,
                      setDrawerVisible
                    );
                  }}
                />
              ),
            },
          ]}
          rowKey="club_id"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>

      <Drawer
        title={
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Challenge Details
          </span>
        }
        placement="right"
        onClose={handleDrawerClose}
        visible={drawerVisible}
        width={500}
        style={{ height: "100vh" }}
      >
        {selectedClub && (
          <Form
            form={form}
            layout="vertical"
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Row gutter={12} style={{ flexGrow: 1 }}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Challenge Image</span>
                  }
                >
                  {isEditing ? (
                    <Upload
                      onChange={handleUploadChange}
                      fileList={fileList}
                      beforeUpload={() => false} // Prevent automatic upload
                    >
                      <Button>Click to upload</Button>
                    </Upload>
                  ) : (
                    <Image
                      src={selectedClub?.club_image}
                      alt="Club Image"
                      style={{
                        borderRadius: "10px",
                        maxWidth: "100%",
                        maxHeight: "200px",
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="club_name"
                  label={
                    <span style={{ fontWeight: "bold" }}>Challenge Name</span>
                  }
                >
                  {isEditing ? (
                    <Input defaultValue={selectedClub.club_name} />
                  ) : (
                    <span>{selectedClub?.club_name}</span>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontWeight: "bold" }}>Admin Name</span>}
                >
                  <span>{selectedClub?.admin_name}</span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} style={{ flexGrow: 1 }}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Members Count</span>
                  }
                >
                  <span>{selectedClub?.members_count}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Challenge Active Score
                    </span>
                  }
                >
                  <span>{selectedClub?.club_active_score}</span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} style={{ flexGrow: 1 }}>
              <Col span={16}>
                <Form.Item
                  label={<span style={{ fontWeight: "bold" }}>Start Date</span>}
                >
                  <span>
                    {new Date(selectedClub?.created_at).toLocaleDateString()}
                  </span>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="end_date"
                  label={<span style={{ fontWeight: "bold" }}>End Date</span>}
                >
                  <span>
                    {new Date(selectedClub?.end_date).toLocaleDateString()}
                  </span>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Host"
              name="host_id"
              rules={[{ required: true, message: "Please select a host" }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select host"
                optionFilterProp="children"
                onClick={() => fetchHostList(setHostList)} // Fetch the host list when the Select component is clicked
                onChange={(value) => setSelectedHost(value)}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                disabled={!isEditing}
              >
                {hostList.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.first_name || "Unnamed"}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="description"
              label={<span style={{ fontWeight: "bold" }}>Description</span>}
            >
              {isEditing ? (
                <Input.TextArea defaultValue={selectedClub.description} />
              ) : (
                <span>{selectedClub?.description}</span>
              )}
            </Form.Item>

            <Form.Item
              name="reward_rule"
              label={<span style={{ fontWeight: "bold" }}>Reward Rules</span>}
            >
              {isEditing ? (
                <Input defaultValue={selectedClub.reward_rule} />
              ) : (
                <span>{selectedClub.reward_rule}</span>
              )}
            </Form.Item>

            <Form.Item
              name="categories"
              label={<span style={{ fontWeight: "bold" }}>Categories</span>}
            >
              {isEditing ? (
                <Select
                  mode="multiple" // Allows multiple selection
                  defaultValue={selectedClub.categories || []} // Ensure default is an array
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    form.setFieldsValue({ categories: value })
                  }
                >
                  {/* Categories options */}
                  <Option value="Swimming">Swimming</Option>
                  <Option value="Cycling/spinning">Cycling/spinning</Option>
                  <Option value="Yoga">Yoga</Option>
                  <Option value="Walking/running">Walking/running</Option>
                  <Option value="Strength_training">Strength training</Option>
                  <Option value="Sports">Sports</Option>
                </Select>
              ) : (
                <span>{selectedClub.categories.join(", ")}</span> // Display as a comma-separated string
              )}
            </Form.Item>

            <Row gutter={12} style={{ marginTop: 20, paddingBottom: 20 }}>
              {/* Always show Edit and Delete buttons */}
              <Col span={12}>
                {!isEditing ? (
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={handleEdit}
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#FFEB3B", // Yellow color
                      borderColor: "#FFEB3B", // Yellow border color
                      color: "#000", // Dark text color for contrast
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={handleSave}
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#FFEB3B", // Yellow color
                      borderColor: "#FFEB3B", // Yellow border color
                      color: "#000", // Dark text color for contrast
                    }}
                  >
                    Save
                  </Button>
                )}
              </Col>

              <Col span={12}>
                {!isEditing ? (
                  <Button
                    type="danger"
                    block
                    size="large"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "#ff4d4f",
                      borderColor: "#ff4d4f",
                    }}
                    onClick={() => handleDelete(selectedClub.club_id)} // Trigger the delete function
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    type="default"
                    block
                    size="large"
                    onClick={() => setIsEditing(false)}
                    style={{
                      borderRadius: "8px",
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 20, paddingBottom: 20 }}>
              <Col span={24}>
                <Button
                  icon={<ShareAltOutlined />}
                  block
                  size="large"
                  onClick={() =>
                    generateShareLink(
                      selectedClub.club_id,
                      setGeneratedLink,
                      setIsModalVisible,
                      setDrawerVisible
                    )
                  }
                  style={{
                    borderRadius: "8px",
                  }}
                >
                  Generate Share Link
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>

      <GeneratedLinkModal
        isVisible={isModalVisible}
        link={generatedLink}
        onClose={handleModalClose}
        onCopy={handleCopyLink}
      />

      <Drawer
        title={
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Create Challenge
          </span>
        }
        placement="right"
        onClose={handleCreateClubDrawerClose}
        visible={createClubDrawerVisible}
        width={500}
        style={{ height: "100vh" }}
      >
        <ClubCreationForm
          onCreateClub={() => {
            fetchClubData();
            setCreateClubDrawerVisible(false);
          }}
        />
      </Drawer>
    </div>
  );
};

export default AppChallenges;
