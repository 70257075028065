import { EditFilled, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  InputNumber,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import { ImageUpload } from "../../components/ImageUpload";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import configData from "../../config.json";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi } from "../../helpers/ApiFunctions";
import "./index.css";

export default function ShopifyDiscounts({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [totalDiscountCodes, setTotalDiscountCodes] = useState(8);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeIcon, setActiveIcon] = useState("");
  const [activeImage, setActiveImage] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [discountType, setDiscountType] = useState(null);
  const [custEligibility, setCustEligibility] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [discountAppliesTo, setDiscountAppliesTo] = useState("collections");
  const [spendType, setSpendType] = useState("quantity");
  const [buyType, setBuyType] = useState("collections");
  const [buyValueType, setBuyValueType] = useState("percentage");
  const [dropdownListValues, setDropdownListValues] = useState([]);
  const [buyDropdownListValues, setBuyDropdownListValues] = useState([]);
  const [purchaseRequirement, setPurchaseRequirement] = useState(null);
  const [redeemLink, setRedeemLink] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [strikeOffPrice, setStrikeOffPrice] = useState("");

  const custSegments = [
    { id: 363736563960, label: "Customers who have purchased at least once" },
    {
      id: 363736596728,
      label: "Abandoned Checkout Customers - 30 days (No orders)",
    },
    { id: 363736629496, label: "Waitlisted Customers" },
    { id: 363736662264, label: "Customers who purchased more than once" },
    { id: 450782593272, label: "Customers who haven't purchased" },
    { id: 465945428216, label: "Email subscribers" },
    { id: 472393187576, label: "Abandoned checkouts in last 30 days" },
  ];
  const isMaxValues = selectedValues?.length === 3;

  const [form] = Form.useForm();
  const onFinish = (values) => {
    // Define selectedItems based on discountAppliesTo
    const selectedItems =
      discountAppliesTo === "products"
        ? values.entitled_product_ids || []
        : values.entitled_collection_ids || [];

    // Extract titles for the selected IDs
    const selectedTitles = selectedItems
      .map((id) => {
        const matchedItem = dropdownListValues.find(
          (item) =>
            (discountAppliesTo === "products"
              ? item.id
              : item.collection_id) === id
        );
        return matchedItem ? matchedItem.title : null;
      })
      .filter(Boolean);

    // Debugging: Log selected items and titles
    console.log("Selected Items:", selectedItems);
    console.log("Selected Titles:", selectedTitles);

    const payload = {
      title: values.discount_title,
      value_type:
        values.value_type === "free" || discountType === "shipping"
          ? "percentage"
          : values.value_type,
      value: `${
        (discountType === "buyXGetY" && buyValueType === "free") ||
        discountType === "shipping"
          ? -100
          : -values.value
      }`,
      customer_selection: values.customer_eligibility || null,
      target_type: discountType === "shipping" ? "shipping_line" : "line_item",
      target_selection:
        discountType === "shipping" || discountType === "order"
          ? "all"
          : "entitled",
      allocation_method:
        discountType === "shipping" || discountType === "buyXGetY"
          ? "each"
          : "across",
      starts_at: values.start_date.format(),
      ends_at: values.end_date ? values.end_date.format() : null,
      prerequisite_collection_ids: values.prerequisite_collection_ids || [],
      prerequisite_product_ids: values.prerequisite_product_ids || [],
      prerequisite_customer_ids: values.prerequisite_customers || [],
      entitled_product_ids: values.entitled_product_ids || [],
      entitled_collection_ids: values.entitled_collection_ids || [],
      entitled_product_titles:
        discountAppliesTo === "products" ? selectedTitles : [],
      entitled_collection_titles:
        discountAppliesTo === "collections" ? selectedTitles : [],
      prerequisite_to_entitlement_quantity_ratio: {
        prerequisite_quantity:
          values.spend_type === "quantity" ? values.spend_value : null,
        entitled_quantity: values.entitled_quantity || null,
      },
      prerequisite_to_entitlement_purchase: {
        prerequisite_amount:
          values.spend_type === "amount" ? values.spend_value : null,
      },
      prerequisite_subtotal_range: values.prerequisite_subtotal_range
        ? { greater_than_or_equal_to: values.prerequisite_subtotal_range }
        : null,
      prerequisite_quantity_range: values.prerequisite_quantity_range
        ? { greater_than_or_equal_to: values.prerequisite_quantity_range }
        : null,
      allocation_limit: 1,
      coupon_code: values.discount_code,
      redeem_value: values.redeem_value,
      offer_price: values.offer_price,
      strike_off_price: values.strike_off_price,
      redeem_link: values.redeem_link,
      once_per_customer: true,
      description: values.description,
      terms_and_conditions: values.terms_and_conditions,
    };

    console.log("payload from shopify discount:", payload);

    isEdit ? editDiscountCode(payload) : createDiscountCode(payload);
  };

  const createDiscountCode = async (payload) => {
    const res = await getApi(payload, "createDiscountCode", "cubeclub/");
    console.log(res);
    if (res.success) {
      uploadCouponImage(res?.data[0]?.coupon_code_id);
      resetFunc();
      getDiscountCodes();
    }
  };

  const editDiscountCode = async (payload) => {
    console.log(selected.id);
    const res = await getApi(
      { ...payload, price_rule_id: selected.id },
      "editDiscountCode",
      "cubeclub/"
    );
    console.log(res);
    if (res.success) {
      resetFunc();
      uploadCouponImage(selected.coupon_code_id);
      getDiscountCodes();
    }
  };

  const uploadCouponImage = async (coupon_code_id) => {
    if (activeImage !== null) {
      var formdata = new FormData();

      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadDiscountProfile");
      formdata.append("coupon_code_id", coupon_code_id);

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadDiscountProfile`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          getDiscountCodes();
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getDiscountCodes();
    }
  };

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);

  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  useEffect(() => {
    getDiscountCodes();
  }, []);

  useEffect(() => {
    console.log(discountCodes);
  }, [discountCodes]);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const getDiscountCodes = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(payload, "getDiscountCodes", "cubeclub/");
    console.log("discount codes", res.data);
    if (res.data) {
      setDiscountCodes(res.data);
      setTotalDiscountCodes(res.data.length);
    }
    setLoading(false);
  };

  const resetFunc = () => {
    console.log("Reset func called");
    setIsSidebarOpen(false);
    setSelected([]);
    setIsCreate(false);
    setIsEdit(false);
    setActiveImage(null);
    form.resetFields();
  };

  const discountDetailColumns = [
    {
      title: "Display Image",
      dataIndex: "profile",
      key: "profile",
      align: "center",

      render: (text, object) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<UserOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
              size={24}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Discount title",
      dataIndex: "coupon_title",
      key: "coupon_title",
      align: "center",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Discount Code",
      dataIndex: "coupon_code",
      key: "coupon_code",
      align: "center",
      render: (t) => (t ? t : "-"),
    },

    {
      title: "Created at",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      render: (t) => (t == null ? "-" : t),
    },
    {
      title: "Expires at",
      dataIndex: "end_date",
      key: "end_date",
      render: (t) => {
        return t ? t : "-";
      },
    },
    {
      title: "Discount value",
      dataIndex: "offer",
      key: "offer",
      render: (t) => (t ? -t : "-"),
    },
    {
      title: "Discount value type",
      dataIndex: "offer_type",
      key: "offer_type",
      render: (t) => (t == "fixed_amount" ? "Rs." : "%"),
    },
  ];

  const handleRowClick = (row) => {
    console.log("row", row);
    const payload = { price_rule_id: row.price_rule_id };
    getApi(payload, "getDiscountData", "cubeclub/")
      .then((res) => {
        console.log("res", res);
        const data = {
          ...res.data.price_rule,
          redeem_value: row.redeem_value,
          coupon_code: row.coupon_code,
          coupon_title: row.coupon_title,
          coupon_code_id: row.coupon_code_id,
          profile: row.profile,
        };
        console.log(data);
        setSelected(data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsSidebarOpen(true);
  };

  const handleCustomerEligibilityDropdown = (val) => {
    setCustEligibility(val);
    if (val === "prerequisite") {
      const payload = {};
      getApi(payload, "getShopifyCustomers", "cubeclub/").then((response) => {
        console.log(response.data.customers);
        setCustomerList(response.data.customers);
      });
    }
    console.log(val);
  };

  // const handleProductDataDropdown = (val, buyDropdown = false) => {
  //   const isMounted = useRef(true);  // Tracking component mount status

  //   useEffect(() => {
  //     // Cleanup function to set isMounted to false when component unmounts
  //     return () => {
  //       isMounted.current = false;
  //     };
  //   }, []);

  //   console.log(val);
  //   const payload = { type: val };

  //   getApi(payload, "getShopifyProductData", "cubeclub/")
  //     .then((response) => {
  //       if (!isMounted.current) return;  // Prevent state updates if component is unmounted

  //       console.log(response.data);

  //       if (response.data) {
  //         if (buyDropdown) {
  //           if (val === "products") {
  //             setBuyDropdownListValues(response.data.products || []); // Default to empty array if no products
  //           } else {
  //             const data = new Set(
  //               response.data.data?.collects?.map((collect) => collect.collection_id) || [] // Default empty if no collects
  //             );
  //             const collections = Array.from(data).map((collection) => ({
  //               collection_id: collection,
  //             }));
  //             setBuyDropdownListValues(collections);
  //           }
  //         } else {
  //           if (val === "products") {
  //             setDropdownListValues(response.data.products || []); // Default to empty array if no products
  //           } else {
  //             const data = new Set(
  //               response.data.data?.collects?.map((collect) => collect.collection_id) || [] // Default empty if no collects
  //             );
  //             const collections = Array.from(data).map((collection) => ({
  //               collection_id: collection,
  //             }));
  //             console.log(collections);
  //             setDropdownListValues(collections);
  //           }
  //         }
  //       } else {
  //         console.error("Invalid response data:", response);
  //         // Optionally show an error message to the user
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("API error:", error);
  //       // Optionally show an error message to the user
  //     });
  // };

  const handleProductDataDropdown = (val, buyDropdown = false) => {
    console.log(val);
    const payload = { type: val };

    getApi(payload, "getShopifyProductData", "cubeclub/").then((response) => {
      console.log(response.data);
      if (buyDropdown) {
        if (val === "products") {
          setBuyDropdownListValues(response.data.products);
        } else {
          const data = new Set(
            response.data.data.collects.map((collect) => collect.collection_id)
          );
          const collections = Array.from(data).map((collection) => ({
            collection_id: collection,
          }));
          setBuyDropdownListValues(collections);
        }
      } else {
        if (val === "products") {
          setDropdownListValues(response.data.products);
        } else {
          const data = new Set(
            response.data.collects.map((collect) => collect.collection_id)
          );
          const collections = Array.from(data).map((collection) => ({
            collection_id: collection,
          }));
          console.log(collections);
          setDropdownListValues(collections);
        }
      }
    });
  };

  const deleteDiscountCode = async () => {
    const payload = {
      price_rule_id: selected.id,
      coupon_code: selected.coupon_code,
      coupon_code_id: selected.coupon_code_id,
    };

    const res = await getApi(payload, "deleteDiscountCode", "cubeclub/");
    console.log(res);
    if (res.success) {
      setDeletePopup(false);
      resetFunc();
      getDiscountCodes();
    }
  };

  return (
    <>
      {deletePopup && (
        <Popup
          saveText="Delete"
          title="Delete discount"
          saveHandler={() => deleteDiscountCode()}
          cancelHandler={() => {
            setDeletePopup(false);
          }}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure you want to delete discount code{" "}
            <b>{selected.coupon_code}</b>?
          </Text>
        </Popup>
      )}

      <Sidebar
        loading={selected.loading}
        title={
          isCreate
            ? `Create a discount code`
            : isEdit
            ? `Edit Discount`
            : `Discount Details`
        }
        onClose={resetFunc}
        visible={isSidebarOpen}
      >
        <LabelValueContainer
          data={
            isCreate || isEdit
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Discount code"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="display_image" label="Display Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>

                          <Form.Item
                            name="Type"
                            label="Type"
                            rules={[
                              {
                                required: true,
                                message: "Please select the type of discount!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Type of discount"
                              onChange={(val) => {
                                setDiscountType(val);
                                setDiscountAppliesTo("collections");
                                handleProductDataDropdown(val, false);
                                form.resetFields([
                                  "applies_to",
                                  "value_type",
                                  "entitled_product_ids",
                                  "entitled_collection_ids",
                                  "prerequisite_product_ids",
                                  "prerequisite_collection_ids",
                                ]);
                              }}
                              allowClear
                            >
                              <Option value="order">Amount off order</Option>
                              <Option value="product">
                                Amount off products
                              </Option>
                              <Option value="buyXGetY">Buy X get Y</Option>
                              <Option value="shipping">Free shipping</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="discount_title"
                            label="Discount Title"
                            rules={[
                              {
                                required: true,
                                message: "Please input the discount title!",
                              },
                            ]}
                          >
                            <Input placeholder="eg. 15% OFF on Dumbells" />
                          </Form.Item>

                          <Form.Item
                            hasFeedback
                            name="discount_code"
                            label="Discount Code"
                            rules={[
                              {
                                required: true,
                                message: "Please input the discount code!",
                              },
                            ]}
                          >
                            <Input
                              disabled={isEdit}
                              placeholder="Enter discount code"
                            />
                          </Form.Item>

                          <Form.Item
                            name="customer_eligibility"
                            label="Cust. Eligibility"
                            rules={[
                              {
                                required: true,
                                message: "Please select customer eligibility!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Customer Eligibility"
                              onChange={handleCustomerEligibilityDropdown}
                              allowClear
                            >
                              <Option value="all">All Customers</Option>
                              {/* <Option value="segment">
                                Specific customer segments
                              </Option> */}
                              <Option value="prerequisite">
                                Specific customers
                              </Option>
                            </Select>
                          </Form.Item>

                          {custEligibility === "segment" ? (
                            <Form.Item
                              name="prerequisite_customer_segment"
                              label="Select cust. segments"
                            >
                              <Select
                                placeholder="Select customer segments"
                                mode="multiple"
                                allowClear
                              >
                                {custSegments.map(({ id, label }, key) => (
                                  <Option value={id} key={key}>
                                    {label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : custEligibility === "prerequisite" ? (
                            <Form.Item
                              name="prerequisite_customers"
                              label="Select customers"
                            >
                              <Select
                                placeholder="Customer Eligibility"
                                mode="multiple"
                                allowClear
                              >
                                {customerList.map(
                                  ({ id, first_name, last_name }, key) => (
                                    <Option value={id} key={key}>
                                      {first_name} {last_name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          ) : (
                            <></>
                          )}

                          {/* Changing on the basis of type of discount */}
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              borderRadius: 15,
                              padding: discountType ? "10px" : "0px",
                              marginBottom: "10px",
                            }}
                          >
                            {discountType === "order" ? (
                              <>
                                <Form.Item
                                  name="value_type"
                                  label="value_type"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the value type!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Percentage"
                                    initialValue={"percentage"}
                                    allowClear
                                  >
                                    <Option value="percentage">
                                      Percentage
                                    </Option>
                                    <Option value="fixed_amount">
                                      Fixed Amount
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="value"
                                  label="value"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Input type="number" />
                                </Form.Item>
                              </>
                            ) : discountType === "product" ? (
                              <>
                                <Form.Item
                                  name="value_type"
                                  label="Value Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the value type!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Percentage"
                                    initialValue={"percentage"}
                                    allowClear
                                  >
                                    <Option value="percentage">
                                      Percentage
                                    </Option>
                                    <Option value="fixed_amount">
                                      Fixed Amount
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="value"
                                  label="Value"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the spend value!",
                                    },
                                  ]}
                                >
                                  <Input type="number" />
                                </Form.Item>
                                <Form.Item
                                  name="applies_to"
                                  label="Applies To"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select the discount scope!",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      setDiscountAppliesTo(val);
                                      handleProductDataDropdown(val, false);
                                    }}
                                    initialValue={"collections"}
                                    allowClear
                                  >
                                    <Option value="collections">
                                      Specific Collections
                                    </Option>
                                    <Option value="products">
                                      Specific Products
                                    </Option>
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  name={
                                    discountAppliesTo === "collections"
                                      ? "entitled_collection_ids"
                                      : discountAppliesTo === "products"
                                      ? "entitled_product_ids"
                                      : ""
                                  }
                                  label={
                                    discountAppliesTo === "collections"
                                      ? "Select collections"
                                      : discountAppliesTo === "products"
                                      ? "Select products"
                                      : ""
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select at least one!",
                                    },
                                  ]}
                                >
                                  <Select mode="multiple" allowClear>
                                    {dropdownListValues.map(
                                      ({ id, collection_id, title }, key) => (
                                        <Option
                                          value={
                                            discountAppliesTo === "products"
                                              ? id
                                              : collection_id
                                          }
                                          key={key}
                                        >
                                          {discountAppliesTo === "products"
                                            ? title
                                            : collection_id}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </>
                            ) : discountType === "buyXGetY" ? (
                              <>
                                <Form.Item
                                  name="spend_type"
                                  label="Customer spends"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the spend type!",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => setSpendType(val)}
                                    initialValue={"quantity"}
                                    allowClear
                                  >
                                    <Option value="quantity">
                                      Minimum quantity of items
                                    </Option>
                                    <Option value="amount">
                                      Minimum purchase amount
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Row gutter={[8, 8]}>
                                  <Col span={10}>
                                    <Form.Item
                                      name="spend_value"
                                      label={
                                        spendType === "quantity"
                                          ? "Quantity"
                                          : "Amount"
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select the spend value!",
                                        },
                                      ]}
                                    >
                                      <Input type="number" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={14}>
                                    <Form.Item
                                      name="applies_to"
                                      label="Applies To"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select the discount scope!",
                                        },
                                      ]}
                                    >
                                      <Select
                                        onChange={(val) => {
                                          setDiscountAppliesTo(val);
                                          handleProductDataDropdown(val, false);
                                        }}
                                        initialValue={"collections"}
                                        allowClear
                                      >
                                        <Option value="collections">
                                          Specific Collections
                                        </Option>
                                        <Option value="products">
                                          Specific Products
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name={
                                    discountAppliesTo === "collections"
                                      ? "prerequisite_collection_ids"
                                      : discountAppliesTo === "products"
                                      ? "prerequisite_product_ids"
                                      : ""
                                  }
                                  label={
                                    discountAppliesTo === "collections"
                                      ? "Select collections"
                                      : discountAppliesTo === "products"
                                      ? "Select products"
                                      : ""
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select at least one!",
                                    },
                                  ]}
                                >
                                  <Select mode="multiple" allowClear>
                                    {dropdownListValues.map(
                                      ({ id, collection_id, title }, key) => (
                                        <Option
                                          value={
                                            discountAppliesTo === "products"
                                              ? id
                                              : collection_id
                                          }
                                          key={key}
                                        >
                                          {discountAppliesTo === "products"
                                            ? title
                                            : collection_id}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                                <Divider />
                                <p>Customer Gets</p>
                                <Row gutter={[8, 8]}>
                                  <Col span={10}>
                                    <Form.Item
                                      name="entitled_quantity"
                                      label="Quantity"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select the Quantity!",
                                        },
                                      ]}
                                    >
                                      <Input type="number" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={14}>
                                    <Form.Item
                                      name="buy_type"
                                      label="Applies To"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select the discount scope!",
                                        },
                                      ]}
                                    >
                                      <Select
                                        onChange={(val) => {
                                          setBuyType(val);
                                          handleProductDataDropdown(val, true);
                                        }}
                                        initialValue={"collections"}
                                        allowClear
                                      >
                                        <Option value="collections">
                                          Specific Collections
                                        </Option>
                                        <Option value="products">
                                          Specific Products
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.Item
                                  name={
                                    buyType === "collections"
                                      ? "entitled_collection_ids"
                                      : buyType === "products"
                                      ? "entitled_product_ids"
                                      : ""
                                  }
                                  label={
                                    buyType === "collections"
                                      ? "Select collections"
                                      : buyType === "products"
                                      ? "Select products"
                                      : ""
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select at least one!",
                                    },
                                  ]}
                                >
                                  <Select mode="multiple" allowClear>
                                    {buyDropdownListValues.map(
                                      ({ id, collection_id, title }, key) => (
                                        <Option
                                          value={
                                            buyType === "products"
                                              ? id
                                              : collection_id
                                          }
                                          key={key}
                                        >
                                          {buyType === "products"
                                            ? title
                                            : collection_id}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  name="value_type"
                                  label="At discounted value"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the value type!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Percentage"
                                    initialValue={"percentage"}
                                    onChange={(val) => setBuyValueType(val)}
                                    allowClear
                                  >
                                    <Option value="percentage">
                                      Percentage
                                    </Option>
                                    <Option value="fixed_amount">
                                      Amount off each
                                    </Option>
                                    <Option value="free">Free</Option>
                                  </Select>
                                </Form.Item>

                                {buyValueType !== "free" && (
                                  <Form.Item
                                    name="value"
                                    label="value"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input the value!",
                                      },
                                    ]}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                )}
                              </>
                            ) : discountType === "shipping" ? (
                              <>
                                <Form.Item
                                  name="purchase_requirements"
                                  label="Min. purchase requirements"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select the Quantity!",
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) =>
                                      setPurchaseRequirement(val)
                                    }
                                    allowClear
                                  >
                                    <Option value="null">
                                      No minimum requirement
                                    </Option>
                                    <Option value="amount">
                                      Minimum purchase amount
                                    </Option>
                                    <Option value="quantity">
                                      Minimum quantity of items
                                    </Option>
                                  </Select>
                                </Form.Item>
                                {purchaseRequirement &&
                                  purchaseRequirement !== "null" && (
                                    <Form.Item
                                      name={
                                        purchaseRequirement === "amount"
                                          ? "prerequisite_subtotal_range"
                                          : purchaseRequirement === "quantity"
                                          ? "prerequisite_quantity_range"
                                          : ""
                                      }
                                      label={
                                        purchaseRequirement === "amount"
                                          ? "Amount"
                                          : purchaseRequirement === "quantity"
                                          ? "Quantity"
                                          : ""
                                      }
                                    >
                                      <Input type="number" />
                                    </Form.Item>
                                  )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <Form.Item
                            name="redeem_value"
                            label="Redeem Value"
                            rules={[
                              {
                                required: true,
                                message: "Please input the redeem value!",
                              },
                            ]}
                          >
                            <Input type="number" placeholder="eg. 500" />
                          </Form.Item>

                          <Form.Item
                            label="Offer Price"
                            name="offer_price"
                            rules={[
                              {
                                required: true,
                                message: "Please enter offer price",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter offer price"
                              value={offerPrice}
                              onChange={(e) => setOfferPrice(e.target.value)}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Strike Off Price"
                            name="strike_off_price"
                            rules={[
                              {
                                required: true,
                                message: "Please enter strike oof price",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter strike off price"
                              value={strikeOffPrice}
                              onChange={(e) =>
                                setStrikeOffPrice(e.target.value)
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Redeem Link"
                            name="redeem_link"
                            rules={[
                              {
                                required: true,
                                message: "Please enter redeem link",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter redeem link"
                              value={redeemLink}
                              onChange={(e) => setRedeemLink(e.target.value)}
                            />
                          </Form.Item>

                          <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please provide a description for the coupon!",
                              },
                            ]}
                          >
                            <Input.TextArea placeholder="Describe the coupon and its benefits" />
                          </Form.Item>

                          <Form.Item
                            name="terms_and_conditions"
                            label="Terms&Conditions"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please provide the terms and conditions!",
                              },
                            ]}
                          >
                            <Input.TextArea placeholder="Enter terms and conditions for the coupon" />
                          </Form.Item>

                          <Form.Item
                            name="start_date"
                            label="Start date & time"
                            rules={[
                              {
                                required: true,
                                message: "Please select a start date!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"MM/DD/YYYY hh:mm A"}
                              showTime={{ use12Hours: true }}
                            />
                          </Form.Item>

                          <Form.Item
                            name="end_date"
                            label="End date & time"
                            rules={[
                              {
                                required: true,
                                message: "Please select a start date!",
                              },
                            ]}
                          >
                            <DatePicker
                              format={"MM/DD/YYYY hh:mm A"}
                              showTime={{ use12Hours: true }}
                            />
                          </Form.Item>

                          <Row gutter={[8, 8]}>
                            <Col>
                              <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                  {isEdit ? "Save" : "Create"}
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item {...tailFormItemLayout}>
                                <Button
                                  type="secondary"
                                  onClick={
                                    isEdit ? () => setIsEdit(false) : resetFunc
                                  }
                                >
                                  Cancel
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Col span={8}>
                          <Avatar
                            icon={<UserOutlined />}
                            size={100}
                            alt=""
                            src={selected.profile}
                          />
                        </Col>

                        <Col span={8}>
                          <Text type="label" style={{ fontSize: 11 }}>
                            Code
                          </Text>
                          <Text style={{ fontWeight: "bold" }}>
                            {selected.coupon_code}
                          </Text>
                          <br style={{ marginBottom: "1.25rem" }} />
                          <Text type="label" style={{ fontSize: 11 }}>
                            Redeem Cost
                          </Text>
                          <Text>{selected.redeem_value} Dopecoins</Text>
                        </Col>

                        <Col span={8} style={{ textAlign: "end" }}>
                          <EditFilled
                            onClick={() => {
                              setIsEdit(true);
                              console.log("selected", selected);
                              setCustEligibility(selected?.customer_selection);
                              if (
                                selected.prerequisite_collection_ids.length ||
                                selected.prerequisite_product_ids.length
                              ) {
                                setDiscountType("buyXGetY");
                                setDiscountAppliesTo(
                                  selected.prerequisite_collection_ids.length >
                                    0
                                    ? "collections"
                                    : "products"
                                );
                                setSpendType(
                                  selected.prerequisite_to_entitlement_purchase
                                    .prerequisite_amount
                                    ? "amount"
                                    : "quantity"
                                );
                                setBuyType(
                                  selected.entitled_collection_ids.length > 0
                                    ? "collections"
                                    : "products"
                                );
                              } else if (
                                selected.entitled_collection_ids.length ||
                                selected.entitled_product_ids.length
                              ) {
                                setDiscountType("product");
                                setDiscountAppliesTo(
                                  selected.entitled_collection_ids.length > 0
                                    ? "collections"
                                    : "products"
                                );
                              } else if (
                                selected.target_type === "shipping_line"
                              ) {
                                setDiscountType("shipping");
                              } else {
                                setDiscountType("order");
                                console.log("Setting type to order");
                              }
                              setPurchaseRequirement(
                                selected.prerequisite_subtotal_range
                                  ? "amount"
                                  : selected.prerequisite_quantity_range
                                  ? "quantity"
                                  : "null"
                              );
                              form.setFieldsValue({
                                Type: discountType,
                                discount_code: selected?.coupon_code,
                                discount_title: selected?.coupon_title,
                                customer_eligibility:
                                  selected?.customer_selection,
                                prerequisite_customers:
                                  selected?.prerequisite_customer_ids,
                                value: -selected?.value,
                                value_type:
                                  selected?.value_type === "percentage" &&
                                  selected?.value === "-100.0"
                                    ? "free"
                                    : selected?.value_type,
                                applies_to: discountAppliesTo,
                                entitled_product_ids:
                                  selected?.entitled_product_ids,
                                entitled_collection_ids:
                                  selected?.entitled_collection_ids,
                                prerequisite_collection_ids:
                                  selected?.prerequisite_collection_ids,
                                prerequisite_product_ids:
                                  selected?.prerequisite_product_ids,
                                prerequisite_quantity_range:
                                  selected?.prerequisite_quantity_range,
                                prerequisite_subtotal_range:
                                  selected?.prerequisite_subtotal_range,
                                spend_type: spendType,
                                spend_value: selected
                                  ?.prerequisite_to_entitlement_purchase
                                  .prerequisite_amount
                                  ? selected
                                      ?.prerequisite_to_entitlement_purchase
                                      .prerequisite_amount
                                  : selected
                                      ?.prerequisite_to_entitlement_quantity_ratio
                                      .prerequisite_quantity,
                                buy_type: buyType,
                                entitled_quantity: parseInt(
                                  selected
                                    ?.prerequisite_to_entitlement_quantity_ratio
                                    .entitled_quantity
                                ),
                                purchase_requirements: purchaseRequirement,
                                redeem_value: selected?.redeem_value,
                                offer_price: selected?.offer_price,
                                strike_off_price: selected?.strike_off_price,
                                redeem_link: selected?.redeem_link,
                                description: selected?.description,
                                terms_and_conditions:
                                  selected?.terms_and_conditions,
                                start_date: moment(selected?.starts_at),
                                end_date: moment(selected?.ends_at),
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },

                  {
                    label: "Title",
                    value: selected.coupon_title,
                  },
                  { label: "Price Rule ID", value: selected.id },
                  { label: "Start Date", value: selected.starts_at },
                  { label: "Expiry Date", value: selected.ends_at },
                  { label: "Value", value: -selected.value },
                  { label: "Value Type", value: selected.value_type },
                  {
                    label: "Customer Selection",
                    value: selected.customer_selection,
                  },
                  {
                    label: "Eligible Customers",
                    value: (
                      <Row>
                        <Col span={12}>
                          <Row>
                            <div className="pill-container">
                              {selected.prerequisite_customer_ids?.map(
                                (item, key) => (
                                  <div
                                    class="data-pill"
                                    key={key}
                                    style={{ backgroundColor: "" }}
                                  >
                                    {item}
                                  </div>
                                )
                              )}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: "Target Selection",
                    value: selected.target_selection,
                  },
                  {
                    label: "Target Type",
                    value: selected.target_type,
                  },
                  {
                    label: "Products",
                    value: (
                      <Row>
                        <Col span={8}>
                          <Row>
                            <div className="pill-container">
                              {selected.entitled_product_ids?.length
                                ? selected.entitled_product_ids?.map(
                                    (item, key) => (
                                      <div
                                        class="data-pill"
                                        key={key}
                                        style={{ backgroundColor: "" }}
                                      >
                                        {item}
                                      </div>
                                    )
                                  )
                                : "NA"}
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: " ",
                    value: (
                      <Button
                        type="danger"
                        onClick={() => setDeletePopup(true)}
                      >
                        Delete Discount
                      </Button>
                    ),
                    fullWidth: true,
                  },
                ]
          }
        />
      </Sidebar>

      <CardContainer hoverable={false}>
        <BreadCrumb title="Shopify Discounts" />

        <TableContainer
          loading={loading}
          columns={
            write === 0 ? discountDetailColumns : [...discountDetailColumns]
          }
          rowClickHandler={(row) => {
            handleRowClick(row);
          }}
          setIsSidebarOpen={setIsSidebarOpen}
          setisCreate={setIsCreate}
          data={discountCodes}
          total={totalDiscountCodes}
          searchFor="Name"
          userCreation="Discount code"
          functionsHandler={getDiscountCodes}
        />
      </CardContainer>
    </>
  );
}
